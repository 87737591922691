import { useProfil } from 'contexts/ProfilContext';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import clientService from 'services/clientService';
import notificationService from 'services/notificationService';

const Navbar = ({changeSize, fullWidth}) => {
  const {userInfo} = useProfil();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    notificationService.getUserNotification()
    .then(result => setNotifications(result))
    .catch(error => console.error());
  }, []);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };
  
  const changeSidebarForm = () => {
    document.body.classList.toggle('sidebar-icon-only')
    changeSize();
  }

  return (
    <nav
      className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row"
      style={fullWidth ? { marginLeft: '-270px' } : {}}
    >
      <div 
        className="navbar-menu-wrapper d-flex align-items-center justify-content-between"
        style={fullWidth ? { width: '100%' } : {}}
      >
      <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}>            
      </a>
        {!fullWidth && 
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => changeSidebarForm() }>
          <i className="mdi mdi-menu"></i>
        </button>
        }
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item  nav-profile border-0 pl-4">
            
          </li>
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-email-outline"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                  <span className="badge badge-pill badge-primary">View all</span>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                {notifications.map(notification => 
                <React.Fragment key={notification.id}>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={"../../assets/images/faces/face10.jpg"} alt="profile" className="img-sm profile-pic" /> </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark">{notification.project_name} </p>
                      <p className="font-weight-light small-text"> {notification.body} </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                </React.Fragment>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          
          
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                <i className='bi bi-person'></i> {userInfo.first_name} {userInfo.last_name}
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                <Dropdown.Header>
                  Options
                </Dropdown.Header>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={()=>clientService.logout()}>
                  Déconnexion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
