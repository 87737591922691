import axiosNotAuthentified from "config/axiosNotAuthentified"
import ValidationError from "errors/ValidationError";

const offerService = {
    getAllOffers: async () => {
        try {
            const response = await axiosNotAuthentified.get('/v1/offers');
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    orderAnOffer: async (data, offerId) => {
        try {
            await axiosNotAuthentified.post(`/v1/offers/${offerId}`, data);
        } catch (error) {
            const response = error.response;
            if (response) {
                switch (response.status) {
                    case 422:
                        throw new ValidationError(response.data.errors);
                    default:
                        throw error;
                }
            }
            throw error;
        }
    }
}
export default offerService;