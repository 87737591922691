import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import supplierService from "services/supplierService";
import "../../assets/css/admin/supplier-detail-page.scss";
import ModifySupplierModal from "components/admin/ModifySupplierModal";
import AddSupplierProductModal from "components/admin/AddSupplierProductModal";
import UpdateSupplierProductModal from "components/admin/UpdateSupplierProductModal";
import DeleteSupplierProductModal from "components/admin/DeleteSupplierProductModal";
import { useProfil } from "contexts/ProfilContext";

const SupplierDetailPage = () => {
    const { isAdmin } = useProfil();
    const { id, projectId } = useParams();
    const [productShowed, setProductShowed] = useState(0);
    const [supplier, setSupplier] = useState({
        id: 0,
        name: "",
        contact: {phone: "", facebook_username: "", facebook_link: ""},
        products: []
    });

    const loadData = useCallback(() => {
        supplierService.getSupplierById(id)
            .then(result => setSupplier(result))
            .catch(error => console.error(error));
    }, [id]);

    useEffect(() => {
        loadData();
    }, [id, loadData]);

    const showProduct=(index)=>{
        if (index === productShowed) {
            setProductShowed(0);
        } else {
            setProductShowed(index);
        }
    }


    const handleUpdateProduct = (data, productIndex) => {
        const tempProduct = {...supplier.products[productIndex]};
        tempProduct.description = data.description;
        tempProduct.notices = data.notices
        const tempProducts = [...supplier.products];
        tempProducts.splice(productIndex, 1, tempProduct);
        setSupplier({...supplier, products: tempProducts});
    }


    return (
        <Container>
            <div className="add-supplier-link">
                <Link to={`/projects/${projectId}/add-suppliers`}>
                    <Card className="mb-4 add-supplier-card">
                        <Card.Body className="asako-btn-primary">
                            Appuyez ici pour ajouter un nouveau fournisseur
                        </Card.Body>
                    </Card>
                </Link>
            </div>
            <Card>
                <Card.Body className="supplier-detail__card-title">
                    <Card.Title>
                        <h1>{supplier.name}</h1>
                    </Card.Title>
                </Card.Body>
            </Card>
            <Row className="mt-4">
                <Col md={4} className="md-4">
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                <Row>
                                    <Col md={10}>
                                        <h4>Information du fournisseur</h4>
                                    </Col>
                                    <Col md={2}>
                                        <ModifySupplierModal data={supplier} loadData={loadData} id={id}/>
                                    </Col>
                                </Row>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <p> <strong>Location:</strong> <span>{supplier.location}</span></p>
                            <p> <strong>Contact:</strong> </p>
                            <ul>
                                <li><strong>Phone: </strong> <span>{supplier.contact.phone}</span></li>
                                <li><strong>Profil Facebook: </strong><a href={supplier.contact.facebook_link} target="_blank" rel="noopener noreferrer">{supplier.contact.facebook_username}</a></li>
                            </ul>
                            <p> <strong>Heure d'ouverture:</strong> <span>{supplier.opening_time}</span> </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card className="supplier__product-card">
                        <Card.Header>
                            <Card.Title>
                                <Row>

                                    <Col md={10}>
                                        <h4>Produit & Service</h4>
                                    </Col>
                                    <Col md={2}>
                                        <AddSupplierProductModal loadData={loadData} id={id}/>
                                    </Col>
                                </Row>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="body">
                            <Table striped bordered className="supplier__product-table"> 
                                <thead>
                                    <tr>
                                        <th>Produit</th>
                                        <th className="unit_price">Prix (Ar)</th>
                                        <th>Description</th>
                                        <th>Avis</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {supplier.products.map((product, index) =>
                                        <tr key={product.id}>

                                        {productShowed === product.id ? 
                                            <>
                                                <td colSpan={4} onClick={()=>showProduct(product.id)} className="global_information">
                                                    <p><strong>Produit: </strong>{product.name}</p>
                                                    <p>
                                                        <strong>Description: </strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: product.description}}
                                                        />
                                                    </p>
                                                    <p><strong>Prix: </strong>{product.unit_price.toLocaleString()}</p>
                                                    <p><strong>Avis:</strong></p>
                                                    <ul>
                                                        {product.notices.map((notice, index) => <li key={index}>{notice}</li>)}
                                                    </ul>
                                                </td>
                                                <td className="action">
                                                    <div className="asako-btn-group">
                                                        <UpdateSupplierProductModal
                                                        oldData={product}
                                                        supplier_id={supplier.id}
                                                        loadData={loadData}
                                                        update={handleUpdateProduct}
                                                        productIndex={index}
                                                        />
                                                        <DeleteSupplierProductModal
                                                        product={product}
                                                        supplier={supplier}
                                                        loadData={loadData}
                                                        />
                                                    </div>
                                                </td>
                                            </>
                                            : 
                                            <>
                                                <td onClick={()=>showProduct(product.id)} className="name">{product.name}</td>
                                                <td onClick={()=>showProduct(product.id)} className="unit_price">{product.unit_price.toLocaleString()}</td>
                                                <td onClick={()=>showProduct(product.id)} className="description">
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: product.description}}
                                                    />
                                                </td>
                                                <td onClick={()=>showProduct(product.id)} className="notice">{product.notices.length > 0 ? product.notices[0] : 'Aucun Avis'}</td>
                                                <td className="action">
                                                    <div className="asako-btn-group">
                                                        <UpdateSupplierProductModal
                                                        oldData={product}
                                                        supplier_id={supplier.id}
                                                        loadData={loadData}
                                                        />
                                                        { isAdmin(projectId) &&
                                                            <DeleteSupplierProductModal
                                                            product={product}
                                                            supplier={supplier}
                                                            loadData={loadData}
                                                            />
                                                        }
                                                    </div>
                                                </td>
                                            </>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SupplierDetailPage;