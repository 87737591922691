import getAxiosInstance from "config/axiosConfig";

const utilService = {
    configureRoleOption (roles) {
        const roleOptions = [];
        roles.forEach(role => {
            roleOptions.push({value: role.id, label: role.name})
        });
        return roleOptions;
    },

    configureProfilOption (profils) {
        const profilOptions = [];
        profils.forEach(profil => {
            profilOptions.push({value: profil.id, label: profil.name})
        });
        return profilOptions;
    },

    parseDateFromDatePicker(date) {
        if (!date) {
            return "";  
        }
        return date.toLocaleString().split(' ')[0];
    },

    getFile: async (filePath) => {
        const response = await getAxiosInstance().get(`v1/files`, {params: {filepath: filePath}});
        return response.data.url;
    },
    
    downloadFile: async (filePath) => {
        const response = await getAxiosInstance().get(`v1/files/download`, {responseType: 'blob',params: {filepath: filePath}});
        return response.data;
    }
}

export default utilService;