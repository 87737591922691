import getAxiosInstance from "config/axiosConfig";

const teamService = {

    /**
     * Enleve le membre du projet
     * 
     * @param {Int16Array} memberId l'identifiant du membre
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns void
     */
    deleteMember: async (memberId, projectId) => {
        const response = await getAxiosInstance().delete(`v1/projects/${projectId}/members/${memberId}`);
        return response.data;
    },


    /**
     * Met à jour les informations du membre
     * 
     * @param {Array} data Les informations du membre
     * @param {Int16Array} memberId l'identifiant du membre
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns 
     */
    updateMember: async (data, memberId, projectId) => {
        const response = await getAxiosInstance().put(`v1/projects/${projectId}/members/${memberId}`, data);
        return response.data;
    },

    /**
     * Ajoute un nouveau membre à l'équipe du projet actuelle
     * 
     * @param {Array} data Les informations du nouveau membre
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns 
     */
    addNewMemberToProject: async (data, projectId) => {
        const response = await getAxiosInstance().post(`v1/projects/${projectId}/members`, data);
        return response.data;
    },

    /**
     * Récupère tous les postes
     */
    getAllRole: async () => {
        const response = await getAxiosInstance().get(`v1/roles`);
        return response.data;
    },

    /**
     * Récupère tous les membres du projet
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns les membres du projets
     */
    getMemberByProjectId: async (projectId) => {
        const response = await getAxiosInstance().get(`/v1/projects/${projectId}/members`);
        return response.data;
    }

}
export default teamService;

export const getMemberFullName = (member) => {
    return member.first_name + " " + member.last_name;
}