import React, { useState, useRef, useEffect } from "react";

import TaskCard from "./TaskCard";
import { Form } from "react-bootstrap";
import taskService from "services/taskService";
import { useParams } from "react-router-dom";
import ColumnOptionModal from "./ColumnOptionModal";
import TaskColumnMoveModal from "./TaskColumnMoveModal";
import FormText from "components/FormText";
import notifyService from "services/notifyService";

const TaskColumn = ({checkCard, updateCard, addNewCard, updateColumnTitle, removeCard, column, moveCard, columnIndex, moveColumn, removed}) => {
    const { projectId } = useParams();
    const [showOption, setShowOption] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [showInputName, setShowInputName] = useState(false);
    const [formName, setFormName] = useState("");
    const [formData, setFormData] = useState({
        columnTitle: column.title
    });
    
    const removeColumn = () => {
        taskService.deleteColumn(projectId, column.id)
        .then(() => {
            removed(columnIndex);
        })
        .catch(error => console.error(error));
    }

    const handleChangeFormData = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleCheckCard = (cardIndex) => {
        checkCard(columnIndex, cardIndex);
    }

    const handleSubmitUpdateColumnTitle = (event) => {
        event.preventDefault();
        taskService.updateColumnTitle(formData.columnTitle, projectId, column.id)
        .then( () => {
            updateColumnTitle(columnIndex, formData.columnTitle);
        })
        .catch(error => notifyService.error(error))
        .finally(() => setShowInputName(false));
    }

    const handleShowInput = () => setShowInput(true);
    const handleCloseInput = () => {
        setShowInput(false);
        setFormName("");
    };
    const inputRef = useRef(null);
    const handleCloseOptionColumn = () => {
        setShowOption("")
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formName.trim() === "") return;
    
        const newCard = { name: formName };
    
        taskService.addCard({ name: formName }, column.id, projectId)
            .then(result => {
                newCard.id = result.id;
                newCard.task_column_id = result.task_column_id;
                newCard.position = result.position;
    
                addNewCard(columnIndex, newCard);
                setFormName(""); 
            })
            .catch(error => console.error(error));
    };
    
    const handleUpdateCard = (card, index) => {
        updateCard(columnIndex, index, card);
    };

    useEffect(() => {
        if (showInput && inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [showInput]);

    const handleMoveColumn = (actualPosition, targetPosition) => {
        handleCloseOptionColumn();
        moveColumn(actualPosition, targetPosition);
    }

    const handleRemoveCard =  (cardIndex) => {
        removeCard(columnIndex, cardIndex);
    }

    return (
        <>
            <div className="task-column">
                <div className="header">
                    {showInputName ? 
                    <Form onSubmit={handleSubmitUpdateColumnTitle}>
                        <div className="form-group-column">
                            <FormText 
                            formClass="form-name"
                            name={"columnTitle"}
                            value={formData.columnTitle}
                            handleChange={handleChangeFormData}
                            />
                            <button type="submit" className="asako-btn-icon asako-btn-primary">
                                <i className="bi bi-check"></i>
                            </button>
                            <button type="reset" className="asako-btn-icon asako-btn-secondary" onClick={() => setShowInputName(false)}>
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                    </Form>
                    :    
                    <h3 onClick={() => setShowInputName(true)}>{column.title}</h3>
                    }
                    <div className="column-setup" onClick={() => setShowOption("column-option")}>
                        <i className="bi bi-three-dots"></i>
                    </div>
                    {showOption === "column-option" && <ColumnOptionModal close={handleCloseOptionColumn} setShowOption={setShowOption} removeColumn={removeColumn}/>}
                    {showOption === "move-column-option" && <TaskColumnMoveModal close={handleCloseOptionColumn} column={column} move={handleMoveColumn}/>}
                </div>
                <div className="body">
                    {column.cards.map((card, index) => (
                        <TaskCard 
                        key={card.id} 
                        moveCard={moveCard} 
                        taskCard={card} 
                        index={index} 
                        update={handleUpdateCard} 
                        removed={handleRemoveCard} 
                        columnIndex={columnIndex} 
                        checkCard={handleCheckCard}
                        />))}
                    {showInput && (
                    <div className="add-card-input">
                        <Form onSubmit={handleSubmit} onBlur={handleCloseInput}>
                            <Form.Control
                                ref={inputRef}
                                id="add-card-form"
                                placeholder="Saisissez le titre de la nouvelle carte"
                                value={formName}
                                onChange={(event) => setFormName(event.target.value)}
                                autoFocus
                            />
                            <button type="submit" className="asako-btn asako-btn-primary">Ajouter une carte</button>
                            <button type="button" className="asako-btn-icon asako-btn-secondary" onClick={handleCloseInput}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </Form>
                    </div>
                    )}
                    {!showInput && 
                    <div className="add-card" onClick={handleShowInput}>
                        <span className="icon">
                            <i className="bi bi-plus-lg"></i>
                        </span>
                        <span> Ajouter une carte</span>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}
export default TaskColumn;