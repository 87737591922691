import React from "react"
import Header from "../../../components/home/Header"
import SubHeader from "../../../components/home/SubHeader"
import "../../../assets/css/client/home/home.scss";
import "../../../assets/css/client/home/offer.scss";
import Offer from "../../../components/home/Offer";
import Contact from "../../../components/home/Contact";
import FeatureList from "components/home/FeatureList";


const Home = () => {

    return (
        <>  
            <Header />
            <SubHeader />
            <Offer />
            <FeatureList />
            <Contact />
        </>
    )

}
export default Home