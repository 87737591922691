import React, { useState, lazy, Suspense } from "react";

import { Badge, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import CardOptionModal from "./CardOptionModal";
import moment from "moment";
import taskService from "services/taskService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CardMoveOption from "./CardMoveOption";

const InfoCardModal = lazy(() => import("./InfoCardModal"));


const TaskCard = ({checkCard, taskCard, index, update, moveCard, columnIndex, removed}) => {
    const {projectId} = useParams();
    const [showInfo, setShowInfo] = useState(false);
    const [showOption, setShowOption] = useState("");


    const closeOption = () => setShowOption("");
    const toggleOption = () => setShowOption(showOption === "" ? "options" : "");
    const handleShowInfo = () => setShowInfo(true);
    const handleCloseInfo = () => setShowInfo(false);

    const hasContent = () => {
        if (
            !!taskCard.due_datetime ||
            !!taskCard.description ||
            taskCard.nb_comment > 0 ||
            taskCard.nb_attachment > 0
        ) {
            return true;
        } 
        return false;
    }

    const getStatus = () => {
        if (taskCard.done) return 'done';
        else {
            const dueDate = moment(taskCard.due_datetime, 'YYYY-MM-DD HH:mm:ss');
            if (dueDate.isAfter(moment())) {
                return 'soon';
            } 
            return 'late'
        }
    }

    const getBgDueDate = () => {
        switch (getStatus()) {
            case 'done':
                return 'success';
            case 'soon':
                return 'warning';
            case 'late':
                return 'danger';
            default:
                return 'not-defined';
        }

    }
    
    const handleCheck = () => {
        if (taskCard.done === 1) {
            taskService.uncheckCard(projectId, taskCard.task_column_id, taskCard.id)
            .then(() => {
                checkCard(index);
            })
            .catch(error => console.error(error));
        } else if (taskCard.done === 0) {
            taskService.checkCard(projectId, taskCard.task_column_id, taskCard.id)
            .then(() => {
                checkCard(index);
            })
            .catch(error => console.error(error));
        }
    }

    const getDueMessage = () => {
        if (getStatus() === 'done') return 'Terminer';
        else if (getStatus() === 'soon') return 'Bientôt';
        else if (getStatus() === 'late') return 'En retard';
        else if (getStatus() === 'not-defined') return '';
    }

    return (
        <>
            {showInfo && (
                <Suspense fallback={<Spinner animation="border"/>}>
                    <InfoCardModal 
                    columnIndex={columnIndex} 
                    moveCard={moveCard} 
                    card={taskCard} 
                    showInfo={showInfo} 
                    close={handleCloseInfo} 
                    update={update} 
                    cardIndex={index}
                    remove={removed}
                    />
                </Suspense>
            )}
            <div className="task-card">
                <div className="header">
                    <div className="cover">
                        {/* <img src="/assets/img/Asako.png" alt="cover"></img> */}
                    </div>
                    <div className="modify-icon" onClick={toggleOption}>
                        <i className="bi bi-pencil"></i>
                    </div>
                </div>
                <div className="task-card-content" style={ hasContent() ? {minHeight: '70px'} : undefined } onClick={handleShowInfo}>

                    <h5 className="title">
                        {taskCard.name} 
                    </h5>


                </div>
                <div className="content-icons">

                    {taskCard.followed && 
                    <OverlayTrigger
                    placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Vous suivez cette carte</Tooltip>}
                        >
                        <span><i className="bi bi-eye"></i></span>
                    </OverlayTrigger>
                    }
                    
                    {!!taskCard.description && 
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Cette carte comporte une description</Tooltip>}
                    >
                        <span><i className="bi bi-justify-left"></i></span>
                    </OverlayTrigger>
                    }

                    {taskCard.nb_attachment > 0 && 
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Cette carte possède des pièces jointes</Tooltip>}
                    >
                        <span><i className="bi bi-paperclip"></i>{taskCard.nb_attachment}</span>
                    </OverlayTrigger>
                    }

                    {taskCard.nb_comment > 0 && 
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Cette carte possède des commentaires</Tooltip>}
                    >
                        <span><i className="bi bi-chat"></i> {taskCard.nb_comment}</span>
                    </OverlayTrigger>

                    }
                </div>
                    
                {(!!taskCard.due_datetime && moment(taskCard.due_datetime, 'YYYY-MM-DD HH:mm:ss').isValid())&& 
                <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{getDueMessage()}</Tooltip>}
                >
                    <Badge 
                    className="due_datetime-badge" 
                    bg={getBgDueDate()} 
                    onClick={ () => handleCheck()}>
                        <i className="bi bi-clock"></i> {moment(taskCard.due_datetime, 'YYYY-MM-DD HH:mm:ss').local('fr').format('ll')}
                    </Badge>
                </OverlayTrigger>
                }
            </div>

            {showOption === "options" && <CardOptionModal card={taskCard} cardIndex={index} close={closeOption} setShowOption={setShowOption} removed={removed}/>}
            {showOption === "move-card" && <CardMoveOption close={closeOption} move={moveCard} card={taskCard} columnIndex={columnIndex} cardIndex={index}/>}
        
        </>
    );
}

export default TaskCard;
