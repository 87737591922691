import React from "react"
import { Modal } from "react-bootstrap"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import taskService from "services/taskService";

const DeleteTaskCardModal = ({card, remove, close}) => {
    const {projectId} = useParams();

    const handleRemoveCard = () => {
        taskService.deleteCard(projectId, card.task_column_id, card.id)
        .then(() => {
            remove();
            close();
        })
        .catch(error => console.error(error));
    }

    return (
        <>
            
            <>
                <Modal.Header className="header">
                    <h4>Archiver</h4>
                    <i 
                    className="bi bi-x-lg"
                    onClick={close}
                    ></i>
                </Modal.Header>
                <Modal.Body className="body">
                    <p>Vous êtes sur le point de supprimer la carte <strong>{card.name}</strong> de manière définitive</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                    className="asako-btn asako-btn-secondary"
                    onClick={close}
                    >Annuler</button>
                    <button 
                    className="asako-btn asako-btn-danger"
                    onClick={handleRemoveCard}
                    >Supprimer</button>
                </Modal.Footer>
            </>
        </>
    )
}

export default DeleteTaskCardModal;