import React, { useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import supplierService from "services/supplierService";

const ModifySupplierModal = ({data, loadData, id}) => {
    const [formData, setFormData] = useState({
        name: data.name || "",
        location: data.location || "",
        phone: data.contact.phone || "",
        facebook_username: data.contact.facebook_username || "",
        facebook_link: data.contact.facebook_link || "",
        opening_time: data.opening_time || ""
    });
    const [errors, setErrors] = useState({});

    const [loadSubmit, setLoadSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFormData({
            name: data.name || "",
            location: data.location || "",
            phone: data.contact.phone || "",
            facebook_username: data.contact.facebook_username || "",
            facebook_link: data.contact.facebook_link || "",
            opening_time: data.opening_time || ""
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadSubmit(true);
        const data = {
            name: formData.name,
            location: formData.location,
            contact: {
                phone: formData.phone,
                facebook_username: formData.facebook_username,
                facebook_link: formData.facebook_link
            },
            opening_time: formData.opening_time
        }
        supplierService.updateSupplier(data, id)
        .then(result => {
            loadData();
            handleClose();
        })
        .catch(error => setErrors(error.response.data.errors))
        .finally(() => setLoadSubmit(false));
    }


    return (
        <>
            <button className="asako-btn-icon asako-btn-primary mr-2" onClick={handleShow}>
                <i className="bi bi-pencil-fill"></i>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className="header">
                        <Modal.Title>{data.name}</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-house"></i> </span>
                                        Nom
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom du projet"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        autoFocus
                                        isInvalid={!!showError('name')}
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        {showError('name')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationPhone" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-phone"></i> </span>
                                        Téléphone
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        placeholder="Téléphone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        isInvalid={!!showError('contact.phone')}
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        {showError('contact.phone')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationLocation" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-map"></i> </span>
                                        Lieu
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Lieu"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleChange}
                                        isInvalid={!!showError('location')}
                                        />

                                    <Form.Control.Feedback type="invalid">
                                        {showError('location')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationFacebookUserName" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-facebook"></i> </span>
                                        Compte facebook
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="facebook_username"
                                        placeholder="Compte facebook"
                                        value={formData.facebook_username}
                                        onChange={handleChange}
                                        isInvalid={!!showError('contact.facebook_username')}
                                        />

                                    <Form.Control.Feedback type="invalid">
                                        {showError('contact.facebook_username')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationOpeningTime" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-clock-history"></i> </span>
                                        Heure d'ouverture
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="opening_time"
                                        placeholder="Heure d'ouverture"
                                        onChange={handleChange}
                                        value={formData.opening_time}
                                        isInvalid={!!showError('opening_time')}
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        {showError('opening_time')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="StepSupplierInformationFacebookLink" className="mb-2">
                                    <Form.Label>
                                        <span> <i className="bi bi-link-45deg"></i> </span>
                                        Lien Facebook
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Téléphone"
                                        name="facebook_link"
                                        value={formData.facebook_link}
                                        onChange={handleChange}
                                        isInvalid={!!showError('contact.facebook_link')}
                                        />

                                    <Form.Control.Feedback type="invalid">
                                        {showError('contact.facebook_link')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-secondary" onClick={handleClose} type="button">
                            Fermer
                        </button>
                        <button className="asako-btn asako-btn-primary" type="submit" disabled={loadSubmit}>
                            Sauvegarder {loadSubmit && <Spinner size="sm"/>}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModifySupplierModal;