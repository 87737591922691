import React, { useEffect, useState } from "react";
import "../../../assets/css/projects/teams/task-page.scss";
import TaskColumn from "components/projects/teams/tasks/TaskColumn";
import { Form } from "react-bootstrap";
import taskService from "services/taskService";
import { useParams } from "react-router-dom";
import { useTask } from "contexts/TaskContext";
import TaskColumnLoader from "components/projects/teams/tasks/TaskColumnLoader";

const TaskPage = () => {
    const { projectId } = useParams();
    const { setColumns } = useTask();
    const [tasks, setTasks] = useState([]);
    const [showInput, setShowInput] = useState(false);
    const [titleForm, setTitleForm] = useState("");
    const [onInitLoad, setonInitLoad] = useState(true);

    const handleToggleInputList = () => setShowInput(!showInput);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (titleForm.trim() === "") return;
        const newColumn = {
            title: titleForm,
            cards: []
        };
        setTasks([...tasks, newColumn]);
        taskService.addColumn({title: titleForm}, projectId)
        .then(result => {
            setTasks([...tasks, result]);
            setTitleForm('');
        })
        .catch(error => console.error(error));
    }

    useEffect(() => {
        taskService.getTasks(projectId)
        .then(result => setTasks(result))
        .catch(error => console.error(error));
    }, [projectId]);
    
    useEffect(() => {
        taskService.getTaskColumnByProject(projectId)
        .then(result => setColumns(result))
        .catch(error => console.error(error))
        .finally(() => setonInitLoad(false));
    }, [projectId, tasks, setColumns]);

    const handleMoveCard = (columnIndex, cardIndex, targetPosition, targetColumnId) => {
        const tempTasks = [...tasks];
        const currentColumn = tempTasks[columnIndex];
        const movedCard = currentColumn.cards.splice(cardIndex, 1)[0]; // Retirer la carte
        const oldPosition = movedCard.position;
        movedCard.task_column_id = targetColumnId; // Mise à jour de la colonne cible
      
        if (targetColumnId === currentColumn.id) {
            // Mouvement dans la même colonne
            movedCard.position = targetPosition;
            const movement = oldPosition < targetPosition ? 1 : -1;
        
            currentColumn.cards.forEach((card) => {
                if (between(card.position, oldPosition, targetPosition, movement)) {
                card.position += movement;
                }
            });
        
            // Réinsérer la carte dans sa nouvelle position
            currentColumn.cards.splice(targetPosition - 1, 0, movedCard);
        } else {
            // Mouvement vers une autre colonne
            const targetColumn = tempTasks.find(
                (column) => Number(column.id) === Number(targetColumnId)
            );
        
            // Réajustement des positions dans la colonne actuelle
            currentColumn.cards.forEach((card) => {
                if (Number(card.position) > oldPosition) {
                card.position -= 1;
                }
            });
        
            // Insérer la carte dans la nouvelle colonne
            movedCard.position = targetPosition;
            targetColumn.cards.forEach((card) => {
                if (Number(card.position) >= targetPosition) {
                card.position += 1;
                }
            });
        
            targetColumn.cards.splice(targetPosition - 1, 0, movedCard); // Insérer la carte
        }
      
        setTasks(tempTasks); // Mettre à jour l'état
    };
      
    // Fonction utilitaire pour vérifier si une valeur est entre deux autres
    function between(value, a, b, rule = 0) {
        switch (rule) {
          case 0:
            return value >= Math.min(a, b) && value <= Math.max(a, b);
          case 1:
            return value > Math.min(a, b) && value <= Math.max(a, b);
          case -1:
            return value >= Math.min(a, b) && value < Math.max(a, b);
          default:
            return value >= Math.min(a, b) && value <= Math.max(a, b);
        }
    }

    const handleMoveColumn = (actualPosition, targetPosition) => {
        const tempTasks = [...tasks];
        const movedColumn = tempTasks.splice(actualPosition - 1, 1)[0];
        tempTasks.splice(targetPosition - 1, 0, movedColumn);
        tempTasks.forEach((task, index) => task.position = index + 1);
        setTasks(tempTasks);
    }

    const handleRemoveColumn = (columnIndex) => {
        const tempColumns = [...tasks];
        tempColumns.splice(columnIndex, 1);
        setTasks(tempColumns);
    }

    const handleAddCard = (columnIndex, newCard) => {
        const tempColumns = [...tasks];
        tempColumns[columnIndex].cards.push(newCard);
        setTasks(tempColumns);
    }

    const handleRemoveCard = (columnIndex, cardIndex) => {
        const tempTasks = [...tasks];
        tempTasks[columnIndex].cards.splice(cardIndex, 1);
        setTasks(tempTasks);
    }

    const handleUpdateColumnTitle = (columnIndex, newTitle) => {
        const tempColumns = [...tasks];
        tempColumns[columnIndex].title = newTitle;
        setTasks(tempColumns) ;
    }

    const handleUpdateCard = (columnIndex, cardIndex, newCard) => {
        const tempColumns = [...tasks];
        tempColumns[columnIndex].cards.splice(cardIndex, 1, newCard);
        setTasks(tempColumns);
    }

    const handleCheckCard = (columnIndex, cardIndex) => {
        const tempColumns = [...tasks];
        const tempCards = {...tempColumns[columnIndex].cards[cardIndex]};
        if (tempCards.done === 1)   tempCards.done = 0;
        else                        tempCards.done = 1;
        tempColumns[columnIndex].cards.splice(cardIndex, 1, tempCards);
        setTasks(tempColumns);
    }

    return (
        <>
            <div className="row page-title-header">
                <div className="col-12">
                    <div className="page-header">
                        <h1>Tâches</h1>
                    </div>
                </div>
            </div>
            <div className="task-container">
                {onInitLoad &&
                    <>
                        <TaskColumnLoader cardNumber={3}/>
                        <TaskColumnLoader cardNumber={7}/>
                        <TaskColumnLoader cardNumber={2}/>
                        <TaskColumnLoader cardNumber={5}/>
                    </>
                }
                {tasks.map((task, index) => 
                    <TaskColumn 
                    key={task.id} 
                    columnIndex={index} 
                    column={task} 
                    checkCard={handleCheckCard}
                    moveCard = {handleMoveCard} 
                    moveColumn={handleMoveColumn} 
                    updateColumnTitle={handleUpdateColumnTitle}
                    addNewCard={handleAddCard}
                    removeCard={handleRemoveCard} 
                    updateCard={handleUpdateCard}
                    removed={handleRemoveColumn}
                    />
                )}
                {showInput ? 
                <div className="add-column-task-input task-column">
                    <div className="header">
                        <Form onSubmit={handleSubmit}>
                            <Form.Control 
                            placeholder="Saisissez le titre de la colonne"
                            value={titleForm}
                            onChange={(event) => setTitleForm(event.target.value)}
                            />
                            <button className="asako-btn asako-btn-primary">Ajouter une liste</button>
                            <button type="button" className="asako-btn-icon asako-btn-secondary" onClick={handleToggleInputList}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </Form>
                    </div>
                </div>
                :    
                <div className="add-column-task" onClick={handleToggleInputList}>
                    <span className="icon">
                        <i className="bi bi-plus-lg"></i>
                    </span>
                    <span> Ajouter une liste</span>
                </div>
                }
            </div>
        </>
    )
}
export default TaskPage;
