import React from "react";

const TaskColumnLoader = ({ cardNumber }) => {
    return (
        <div className="task-column skeleton-loader">
            <div className="title"></div>
            {Array.from({ length: cardNumber }, (_, index) => (
                <div className="card" key={index}></div>
            ))}
        </div>
    )
}

export default TaskColumnLoader;