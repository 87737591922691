import React, { createContext, useContext, useState } from 'react';

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
    const [columns, setColumns] = useState([]);
    
    return (
        <TaskContext.Provider value={{ columns, setColumns }}>
        {children}
        </TaskContext.Provider>
    );
};

export const useTask = () => useContext(TaskContext);
