import React from "react";

import '../assets/css/form-required.scss';

const FormRequired = () => {
    return (
        <sup className="form-required">
            *
        </sup>
    )
}
export default FormRequired;