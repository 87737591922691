import React from "react";
import { Modal } from "react-bootstrap";
import UserInfoForm from "./UserInfoForm";

const UserInfoModal = ({show, offerId, close, sended}) => {
    
    return (
        <Modal onHide={close} show={show} centered dialogClassName="asako__modal">
            <Modal.Header className="header">
                <Modal.Title>
                    Voici les informations à remplir pour commander
                    <i className="bi bi-x" onClick={() => close()}></i>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="body">
                <UserInfoForm offerId={offerId} sended={sended}/>
            </Modal.Body>
        </Modal>
    );
}
export default UserInfoModal;