import getAxiosInstance from "config/axiosConfig";

const supplierService = {

    /**
     * Enlever 
     * @param {Int16Array} supplier_id 
     * @param {Int16Array} product_id 
     */
    deleteSupplierProduct: async(supplier_id, product_id) => {
        await getAxiosInstance().delete(`v1/suppliers/${supplier_id}/products/${product_id}`);
    },

    /**
     * Met à jour la description 
     * @param {Array} data 
     * @param {Int16Array} supplier_id 
     * @param {Int16Array} product_id 
     */
    updateSupplierProduct: async (data, supplier_id, product_id) => {
        await getAxiosInstance().put(`v1/suppliers/${supplier_id}/products/${product_id}`, data);
    },

    /**
     * Met à jour les avis sur un produit pour un fournisseur via API
     * 
     * @param {Array} notices 
     * @param {Int16Array} supplier_id 
     * @param {Int16Array} product_id 
     */
    updateSupplierProductNotices: async (notices = [], supplier_id, product_id) => {
        await getAxiosInstance().put(`v1/suppliers/${supplier_id}/products/${product_id}/notices`, {notices: notices});
    },

    /**
     * Récupère les fournisseurs vendant les produits.
     * 
     * @param {Number} products l'identifiant des produits
     * @param {Number} page la page à récupérer
     */
    getSupplierByProductsPaginated: async (products = [], page = 1, projectId) => {
        const response = await getAxiosInstance().get('/v1/suppliers', {
            params: {
                page: page,
                products: JSON.stringify(products),
                project_id: projectId
            }
        });
        const pagination = response.data
        const suppliers = pagination.data;
        suppliers.forEach(supplier => {
            supplier.products = !!supplier.products ? supplier.products : [];
        });
        pagination.data = suppliers;
        return response.data;
    },

    /**
     * Récupère les informations du fournisseur par son identifiant via API
     * 
     * @param {Int16Array} id l'identifiant du fournisseur
     * @returns 
     */
    getSupplierById: async (id) => {
        const response = await getAxiosInstance().get(`v1/suppliers/${id}`);
        return response.data;
    },

    /**
     * Enregistre les informations du nouveau fournisseurs.
     * 
     * @param {Array} data Les informations du fournisseurs.
     */
    createSupplier: async (data) => {
        const response = await getAxiosInstance().post(`v1/suppliers`, data);
        return response.data
    },
    /**
     * Met à jour les informations du fournisseur via API 
     * 
     * @param {Array} data les nouvelles informations du fournisseur
     * @param {Int16Array} id l'identifiant du fournisseur
     */
    updateSupplier: async (data, id) => {
        await getAxiosInstance().put(`v1/suppliers/${id}`, data);
    },

    /**
     * Ajoute un produit aux fournisseurs.
     * 
     * @param {Array} data les informations du nouveau produit du fourniiseur
     */
    addSupplierProduct: async (data) => {
        await getAxiosInstance().post(`v1/suppliers/${data.id}/products`, data);
    },

    validateSupplierInformation: async (data) => {
        await getAxiosInstance().post(`v1/validate-supplier`, data);
    }



}
export default supplierService;