import getAxiosInstance from "config/axiosConfig";
import ValidationError from "errors/ValidationError";

const orderService = {

    updateOrder: async (projectId, orderId, order) => {
        try {
            await getAxiosInstance().put(`v1/projects/${projectId}/orders/${orderId}`, order);
        } catch (error) {
            const response = error.response;
            if (response) {
                switch (response.status) {
                    case 422:
                        throw new ValidationError(response.data.errors);
                    default:
                        throw error;
                }
            }
            throw error;
        }
    },

    getOrderStatus: async () => {
        try {
            const response = await getAxiosInstance().get(`v1/order-status`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getOrderTotal: (order) => {
        let total = 0;
        order.products.forEach(product => {
            total += orderService.getProductOrderTotal(product);
        });
        return total;
    },

    getProductOrderTotal: (productOrder) => {
        return productOrder.unit_price * productOrder.quantity;
    },

    addOrder: async (order, projectId) => {
        try {
            const response = await getAxiosInstance().post(`v1/projects/${projectId}/orders`, order);
            return response.data;
        } catch (error) {
            const response = error.response;
            if (response) {
                switch (response.status) {
                    case 422:
                        throw new ValidationError(response.data.errors);
                    default:
                        throw error;
                }
            }
            throw error;
        }
    },

    getOrder: async (projectId, filters) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/orders`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getOrderById: async (projectId, orderId) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/orders/${orderId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getFilteredOrderByProject: async (projectId, filters = [], page = 1, per_page = 20) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/orders`, {params: {...filters, per_page: per_page, page: page}});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export default orderService;