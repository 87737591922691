import getAxiosInstance from "config/axiosConfig";

const projectService = {

    getUserProfil: async (userId, projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/user-profils/${userId}`);
        return response.data;
    },

    /**
     * Récupère tous les types de projets
     * 
     * @returns Tous les types de projets
     */
    getAllTypeProject: async () => {
        try {
            const response = await getAxiosInstance().get(`/v1/projects/types`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    /**
     * Récupère la liste des projets de l'utilisateur connecté.
     * 
     * @returns Array
     */
    getClientConnectedProject: async () => {
        try {
            const response = await getAxiosInstance().get(`/v1/projects`);
            return response.data[0];
        } catch (error) {
            throw error;
        }
    },

    /**
     * Enregistre un nouveau projet.
     * 
     * @param {Array} data Les informations du nouveau projet
     * @returns 
     */
    createProject: async (data) => {
        try {
            const response = await getAxiosInstance().post(`/v1/projects`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateProject: async (data, id) => {
        try {
            const response = await getAxiosInstance().put(`/v1/projects/${id}`, data);
            return response.data[0];
        } catch (error) {
            throw error;
        }
    },

    deleteProject: async (id) => {
        try {
            const response = await getAxiosInstance().delete(`/v1/projects/${id}`);
            return response.data[0];
        } catch (error) {
            throw error;
        }
    }



}


export default projectService;