import React from "react";

import { Card } from "react-bootstrap";

const SupplierCardLoader = () => {

    return (
        <Card className="mt-4 supplier__card loader">
            <Card.Body>
                <div className="title"/>
                <div className="info-title"/>
                <div className="info"/>
                <div className="info"/>
                <div className="info-title"/>
                <div className="info"/>
                <div className="info"/>
            </Card.Body>
        </Card>
    );
}
export default SupplierCardLoader;