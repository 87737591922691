import getAxiosInstance from "config/axiosConfig";

const dashboardService = {
    getValideYear: async (projectId) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/valide-year`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getProfitsGraphe: async (projectId, year = new Date().getFullYear()) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/profits-graphe`, {params: {year: year}});
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNbMemberInProject: async (projectId) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/nb-orders`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNbOrderInProject: async (projectId) => {
        try {
            const response = await getAxiosInstance().get(`v1/projects/${projectId}/nb-members`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
}
export default dashboardService;