const quillConfig = {

    getQuillModuleForProject () {
        return {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['link'],
                ['clean']
            ],
        };
    },

    getQuillFormatForProject () {
        return [
            'header', 'font', 'size',
            'bold', 'italic',
            'list', 'bullet', 'indent',
            'link'
        ];
    },

    getQuillModuleForProductSupplier: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link'],
            ['clean']
        ],
    },

    getQuillFormatForProductSupplier: [
        'header', 'font', 'size',
        'bold', 'italic',
        'list', 'bullet', 'indent',
        'link'
    ]

}

export default quillConfig;