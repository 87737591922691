import React, { useEffect, useState } from "react";  
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CreatableSelect from "react-select/creatable";
import productService from "services/productService";
import supplierService from "services/supplierService";

const ProductOrderForm = ({oldData, submit}) => {
    const {projectId} = useParams();
    const [formData, setFormData] = useState({name: oldData?.name||'', type: !!oldData ? {label: oldData?.product_type, value: oldData?.product_type} : '', quantity: oldData?.quantity||undefined, unit_price: oldData?.unit_price||undefined, description: oldData?.description||'', state: oldData?.state||undefined, total_price: oldData?.total_price||undefined});
    const [options, setOptions] = useState({products: []});

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const productOptions = result.map(item =>  ({
                value: item.name,
                label: item.name
            }));
            setOptions({
                ...options,
                products: productOptions
            });
        })
        .catch(error => console.error(error));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            name: formData.name,
            unit_price: formData.unit_price,
            description: formData.description,
            quantity: formData.quantity,
            product_type: formData.type.value,
            state: formData.state,
            total_price: formData.total_price
        };
        submit(data);
    }
    return (
        <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="productOrderFormName">
                <Form.Label>
                    <span>
                        <i className="bi bi-cart"></i>
                    </span>
                    Produit
                </Form.Label>
                <Form.Control
                    required
                    autoFocus
                    placeholder="Nom du produit ..."
                    value={formData.name}
                    onChange={e => setFormData({...formData, name: e.target.value})}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="productOrderFormProduct">
                <Form.Label>
                    <span> <i className="bi bi-cart"></i> </span>
                    Catégorie
                </Form.Label>
                <CreatableSelect
                    required
                    isClearable
                    value={formData.type}
                    options={options.products}
                    onChange={(selected) => setFormData({...formData, type: selected})}
                    placeholder="Catégorie ..."
                    formatCreateLabel={(value) => `Nouveau "${value}"`}
                    noOptionsMessage={() => "Aucun produit disponible"}
                    loadingMessage={() => "Chargement..."} 
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="productOrderFormQuantity">
                <Form.Label>
                    <span> <i className="bi bi-cart"></i> </span>
                    Prix Unitaire
                </Form.Label>
                <Form.Control
                required
                value={formData.unit_price}
                onChange={e => setFormData({...formData, unit_price: e.target.value})}
                type="number"
                placeholder="Prix unitaire"
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="productOrderFormQuantity">
                <Form.Label>
                    <span> <i className="bi bi-cart"></i> </span>
                    Quantité
                </Form.Label>
                <Form.Control
                required
                value={formData.quantity}
                onChange={e => setFormData({...formData, quantity: e.target.value})}
                type="number"
                placeholder="Quantité ..."
                />
            </Form.Group>


            <Form.Group className="mb-3" controlId="StepSupplierInformationName">
                <Form.Label>
                    <span> <i className="bi bi-info-circle"></i> </span>
                    Description
                </Form.Label>
                
                <ReactQuill
                value={formData.description}
                onChange={value => setFormData({...formData, description: value})}
                theme="snow"
                placeholder="Écrivez les détails ici..."
                />
            </Form.Group>

            <button className="asako-btn asako-btn-primary" type="submit">
                Ajouter
            </button>

        </Form>  
    );
}
export default ProductOrderForm;