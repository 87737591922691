import React, { useEffect, useState } from "react";
import { Badge, Card, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import '../../../assets/css/projects/clients/order-page.scss';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import orderService from "services/orderService";
import { changeDateFormat } from "utils/DateFormatter";
import OrderFilter from "components/projects/clients/OrderFilter";
import AsakoPagination from "components/AsakoPagination";
import UnPaidModal from "components/projects/clients/UnpaidModal";

const OrderPage = () => {
    const history = useHistory();
    const {projectId} = useParams();
    const [pagination, setPagination] = useState({});
    const [filters, setFilters] = useState({
        orders: 1
    });
    const [orders, setOrders] = useState([]);
    const [options, setOptions] = useState({
        orderStatus: []
    });
    const [selected, setSelected] = useState(undefined);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState({
       unPaid: false 
    });

    const isPaid = (order) => {
        return order.is_paid !== 0;
    }

    const getOrderStatusLabel = (order) => {
        return options.orderStatus[order.state];
    }

    const getOrderStatusColor = (order) => {
        const number = order.state;
        if (number >= 0 && number < 5) {
            return '#ff6258';
        } else if (number >= 5 && number < 10) {
            return '#ffaf00';
        } else if (number >= 10 && number < 15) {
            return '#8862e0';
        } else if (number >= 15 && number < 19) {
            return '#5E17EB';
        } else {
            return '#19d895';
        }
    }

    const handleShowUnPaidModal = (value) => {
        setShow({unPaid: true});
        setSelected(value);
    }
    

    useEffect(() => {
        orderService.getOrderStatus()
        .then(result => {
            setOptions({
                ...options,
                orderStatus: result
            });
        })
        .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        orderService.getFilteredOrderByProject(projectId, filters)
        .then(result => {
            setPagination(result);
            setOrders(result.data);
        })
        .catch(error => console.error(error));
    }, [filters]);

    const handleChangePage = (page) => {
        setPage(page);
        orderService.getFilteredOrderByProject(projectId, filters, page)
        .then(result => {
            setPagination(result);
            setOrders(result.data);
        })
        .catch(error => console.error(error));
    }

    const seeDetail = (orderId) => {
        history.push(`/projects/${projectId}/orders/${orderId}`);
    }

    const markAsPaid = async (paid = 0, index = selected) => {
        try {
            const tempOrders = [...orders];
            tempOrders[index].is_paid = paid === 0 ? 1 : 0;
            setOrders(tempOrders);
            await orderService.updateOrder(projectId, tempOrders[index].id, tempOrders[index]);
        } catch (error) {
            console.error(error);
        }
    }

    
    const handleFilter = async (data) => {
        setFilters({...filters, ...data});
    }

    const newCommand = () => {
        history.push(`/projects/${projectId}/new-order`);
    }
    return (
        <>
            <div className="row page-title-header">
                <div className="col-12">
                    <div className="page-header">
                        <h1>Commandes</h1>
                    </div>
                </div>
            </div>
            <div>
                <OrderFilter filtered={handleFilter}/>
                <Card className="order-card">
                    <Card.Header>
                        <h4>
                            Listes des commandes
                        </h4>
                        <button onClick={() => newCommand()} className="asako-btn asako-btn-primary">
                            Nouvelle commande
                        </button>
                    </Card.Header>
                    <Card.Body>
                        <ul>
                            <li><strong>PT: </strong> Prix Total</li>
                            <li><strong>Devise: </strong> Ariary (Ar)</li>
                        </ul>
                        <Form.Select
                        className="form-control"
                        value={filters.orders}
                        onChange={e => setFilters({...filters, orders: e.target.value})}
                        >
                            <option value="1">Tri par date de création croissant</option>
                            <option value="2">Tri par date de création décroissant</option>
                            <option value="3">Tri par date de livraison croissant</option>
                            <option value="4">Tri par date de livraison décroissant</option>
                        </Form.Select>

                        <Table hover>
                            <thead>
                            <tr>
                                <th>Client</th>
                                <th>Responsable</th>
                                <th>Étiquette</th>
                                <th>Produit</th>
                                <th>Date de livraison</th>
                                <th>Lieu de livraison</th>
                                <th style={{textAlign: 'right'}}>PT</th>
                                <th>Observation</th>
                                <th>Status</th>
                                <th>Payé</th>
                            </tr>
                            </thead>
                            <tbody>
                                {orders.length === 0 && 
                                    <tr>
                                        <td colSpan={10} style={{textAlign: 'center'}}> Aucune commande enregistré</td>
                                    </tr>
                                }
                                {orders.map((order, orderIndex)=>
                                <React.Fragment key={order.id}>
                                <tr>
                                    <td onClick={() => seeDetail(order.id)}>
                                        <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>{order.contact}</Tooltip>}
                                        >
                                            <span>
                                            {order.client_name}
                                            </span>
                                        </OverlayTrigger>
                                    </td>
                                    <td onClick={() => seeDetail(order.id)}>{`${order.member_first_name} ${order.member_last_name}`}</td>
                                    <td>
                                        <ul style={{marginBottom: 0}}>
                                            {order.products.map((product, index) => 
                                                <li key={index}>{product.name}</li>
                                            )}
                                        </ul>
                                    </td>
                                    <td onClick={() => seeDetail(order.id)}>
                                    {order.products.map((product, index) => 
                                        <React.Fragment key={index}>
                                            <span>{product.product_type}</span>
                                            {order.products.length-1 > index && <span>, </span>}
                                        </React.Fragment>
                                    )}
                                    </td>
                                    <td onClick={() => seeDetail(order.id)}>{changeDateFormat(order.delivery_date, 'll')}</td>
                                    <td onClick={() => seeDetail(order.id)}>{order.location}</td>
                                    <td onClick={() => seeDetail(order.id)} style={{textAlign: 'right'}}>{order.total_price.toLocaleString()}</td>
                                    <td onClick={() => seeDetail(order.id)} className="description"><div dangerouslySetInnerHTML={{__html: order.notice}}/></td>
                                    <td>
                                        <div className="badge"style={{backgroundColor: getOrderStatusColor(order)}} >{getOrderStatusLabel(order)}</div>
                                    </td>
                                    <td className="is-paid">
                                    <Badge
                                        onClick={() => 
                                        isPaid(order) 
                                            ? handleShowUnPaidModal(orderIndex)
                                            : markAsPaid(order.is_paid, orderIndex)
                                        }
                                        pill
                                        bg={isPaid(order) ? 'success' : 'danger'}
                                    >
                                        {isPaid(order) ? 'Payé' : 'Non Payé'}
                                    </Badge>
                                    </td>
                                </tr>
                                </React.Fragment>
                                )}
                            </tbody>
                        </Table>
                        <AsakoPagination pagination={pagination} page={page} setPage={handleChangePage}/>
                        <UnPaidModal show={show.unPaid} close={() => setShow({unPaid: false})} index={1}  markAsPaid={markAsPaid} />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}
export default OrderPage;

