import getAxiosInstance, { getMultiPartAxiosInstance } from "config/axiosConfig";

const taskService = {

    deleteColumn: async (projectId, columnId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}`);
    },

    deleteCard: async (projectId, columnId, cardId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}`);
    },

    deleteComment: async (projectId, columnId, cardId, commentId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/comments/${commentId}`);
    },

    checkCard: async (projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/check`)
    },

    uncheckCard: async (projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/uncheck`)
    },

    updateCardName: async (newName, projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/name`, {name: newName});
    },

    updateColumnTitle: async (newTitle, projectId, columnId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/title`, {title: newTitle});
    },

    getAttachmentsByCard: async (projectId, columnId, cardId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/attachments`);
        return response.data;
    },

    getCommentsByCard: async (projectId, columnId, cardId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/comments`);
        return response.data;
    },

    removeAttachment: async (attachmentId, projectId, columnId, cardId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/attachments/${attachmentId}`);
    },

    addAttachment: async (attachment, projectId, columnId, cardId) => {
        await getMultiPartAxiosInstance().post(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/attachments`, attachment);
    },

    getNbTaskColumn: async (projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-count`);
        return response.data;
    },

    moveColumn: async (data, projectId, columnId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/move`, data);
    },

    moveCard: async (data, projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/move`, data);
    },

    getTaskColumnById: async (projectId, columnId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns/${columnId}`);
        return response.data;
    },

    getTaskColumnByProject: async (projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns`);
        return response.data;
    },

    addMemberToCard: async (userId, projectId, columnId, cardId) => {
        const data = {
            user_id: userId
        };
        await getAxiosInstance().post(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/members`, data);
    },

    removeMemberFromCard: async (userId, projectId, columnId, cardId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/members/${userId}`);
    },

    getMemberInAndNotInCardByName: async (name, projectId, columnId, cardId) => {
        const params = {
            name: name
        };
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/all-members`, {params: params})
        return response.data;
    },

    updateCardPeriode: async (data, projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/periode`, data);
    },

    addComment: async (comment, projectId, columnId, cardId) => {
        const response = await getAxiosInstance().post(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/comments`, comment);
        return response.data;
    },
    removeComment: async (commentId, projectId, columnId, cardId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/comments/${commentId}`);
    },
    modifyComment: async (comment, projectId, columnId, cardId, commentId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/comments/${commentId}`, comment);
    },

    getCardById: async (projectId, columnId, cardId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}`);
        return response.data;
    },

    updateCardDescription: async (description, projectId, columnId, cardId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/task-columns/${columnId}/task-cards/${cardId}/description`, {description});
    },
    
    addColumn: async (data, projectId) => {
        const response = await getAxiosInstance().post(`v1/projects/${projectId}/task-columns`, data);
        return response.data;
    },

    addCard: async(data, columnId, projectId) => {
        const response = await getAxiosInstance().post(`v1/projects/${projectId}/task-columns/${columnId}/task-cards`, data);
        return response.data;
    },

    getTasks: async(projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/tasks`)
        return response.data;
    }
}
export default taskService;
