import React, { useEffect, useState } from "react";
import '../../assets/css/admin/project-page.scss';
import Project from "../../components/admin/Project";
import AddProjectModal from "../../components/admin/AddProjectModal";
import projectService from "services/projectService";
import Navbar from "templates/shared/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import ProjectLoader from "components/admin/ProjectLoader";
import { useProfil } from "contexts/ProfilContext";

const ProjectPage = () => {
    const [projects, setProjects] = useState([]);
    const [nbProject, setNbProject] = useState(0);
    const [loading, setLoading] = useState(true);
    const loadData = () => {
        projectService.getClientConnectedProject()
        .then(result => {
            setProjects(result.projects);
            setNbProject(result.nb_project);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }

    const handleRemove = (projectIndex) => {
        const tempProjects = [...projects];
        tempProjects.splice(projectIndex, 1);
        setProjects(tempProjects);
        setNbProject(nbProject - 1); 
    }

    const handleUpdate = (data, projectIndex) => {
        const tempProjects = [...projects];
        const tempProject = { ...tempProjects[projectIndex] };
    
        tempProject.name = data.name;
        tempProject.description = data.description;
    
        tempProjects.splice(projectIndex, 1, tempProject);
        setProjects(tempProjects);
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <>
            <div className="projects-container">
                <div className="row page-title-header">
                    <div className="col-12">
                        <div className="page-header">
                        <h1>Configuration <span style={{color: 'var(--primary-color)'}}>des projets</span></h1>
                        </div>
                    </div>
                </div>                
                <AddProjectModal loadData={loadData}/>
                <h3 className="mt-3 mb-4">Vos projets (<span>{nbProject}</span>)</h3>
                <Row>
                    {loading ? (
                        <>
                            <Col md={3}>
                                <ProjectLoader/>
                            </Col>
                        </> 
                    ) : (
                        projects.map((project, index) => (
                            <Col md={3} key={project.id} className="mb-4">
                                <Project 
                                data={project} 
                                loadData={loadData} 
                                remove={handleRemove} 
                                update={handleUpdate}
                                projectIndex={index}
                                />
                            </Col>
                        ))
                    )}
                </Row>
            </div>
        </>
    );
}

export default ProjectPage;
