import React, { useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { contactService } from "services/contactService";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ValidationError from "errors/ValidationError";

const ContactEmail = () => {
    const [formdata, setFormdata] = useState({mail: '', question: ''});
    const [errors, setErrors] = useState({});
    const [onLoad, setLoad] = useState(false);
    const [questionSendedModal, setQuestionSendedModal] = useState(false);

    const initValue = () => {
        setFormdata({mail: '', question: ''});
    }

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };
    
    const handleSubmit = async (event) => {
        setLoad(true);
        event.preventDefault();
        try {
            await contactService.sendQuestion(formdata.mail, formdata.question);
            initValue();
            setQuestionSendedModal(true);
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors(error.fields);
            }
        } finally {
            setLoad(false);
        }
    }
    
    return (
        <>
            <Form className="mail" onSubmit={handleSubmit}> 
                <Form.Group className="mb-3" controlId="contactForm.mail">
                    <Form.Label>Votre email</Form.Label>
                    <Form.Control
                    type="email" 
                    placeholder="nom@exemple.com" 
                    value={formdata.mail}
                    onChange={(event)=>setFormdata({...formdata, mail: event.target.value})}
                    isInvalid={!!showError('mail')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('mail')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactForm.question">
                    <Form.Label>Votre question</Form.Label>
                    <ReactQuill 
                    value={formdata.question} 
                    onChange={(value)=>setFormdata({...formdata, question: value})}
                    className={!!showError('question') ? 'question-editor invalid' : 'question-editor'}
                    autoFocus
                    placeholder="Écriver votre question ..."
                    />
                    <Form.Control
                        isInvalid={!!showError('question')}
                        style={{display: 'none'}}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('question')}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="submit-group">
                    <button type="submit" className="asako-btn asako-btn-primary" disabled={onLoad}>Envoyer {onLoad && <Spinner size="sm"/>}</button>
                </div>
            </Form>
            <Modal show={questionSendedModal} centered dialogClassName="asako__modal new-member__modal" >
                <Modal.Header className="header">
                    <Modal.Title>
                        <h4>Message envoyé</h4>
                    </Modal.Title>
                    <i className="bi bi-x" onClick={() => setQuestionSendedModal(false)}></i>
                </Modal.Header>
                <Modal.Body className="body">
                    Votre <span>question</span> a bien été transmise aux <span>administrateurs de l'application</span>,
                    vous alliez bientôt avoir une <span>réponse</span> de leur part.
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ContactEmail