import React from "react";  
import { Modal } from "react-bootstrap";

const RemoveProductOrderModal = ({show, close, deleted}) => {
    return (
        <Modal show={show} centered onHide={() => close()}>
            <Modal.Header>
                <i className="bi bi-x-lg" onClick={() => close()}></i>
            </Modal.Header>
            <Modal.Body>
                Vous êtes sur le point d'enlver un produit de la commande
            </Modal.Body>
            <Modal.Footer>
                <button className="asako-btn asako-btn-danger" onClick={() => deleted()}>Enlever</button>
            </Modal.Footer>
        </Modal>
    );
}
export default RemoveProductOrderModal;