import React from "react";

import { Button, Card, Col, Row } from 'react-bootstrap';

import CashRegisterCRUD from "components/projects/cashing/CashRegisterCRUD";
import CashMovementTypeCRUD from "components/projects/cashing/CashMovementTypeCRUD";
import "../../../assets/css/projects/cashing/cash-movement-parameter-page.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CashMovementParameterPage = () => {
    return (
        <>
            <div className="row page-title-header">
                <div className="col-12">
                    <div className="page-header">
                        <h1>Paramétrages des mouvements</h1>
                    </div>
                </div>
            </div>
            <Link to={'flows'}>
                <Button className="mb-4">
                    Mouvement de caisse
                </Button>
            </Link>
            <Row className="cash-movement-parameter">
                <Col md={6}>
                    <Card className="card">
                        <Card.Body className="body">
                            <CashRegisterCRUD />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="card">
                        <Card.Body className="body">
                            <CashMovementTypeCRUD />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );

}
export default CashMovementParameterPage;