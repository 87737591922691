import React from "react";
import { Modal } from "react-bootstrap";
import ProductOrderForm from "./ProductOrderForm";

const AddProductOrderModal = ({show, close, added}) => {

    return (
        <Modal show={show} centered className="asako__modal">
            <Modal.Header>
                <h2>Ajouter un produit</h2>
                <i className="bi bi-x-lg" onClick={() => close()}></i>
            </Modal.Header>
            <Modal.Body>
                <ProductOrderForm submit={added}/>
            </Modal.Body>
        </Modal>
    );
}
export default AddProductOrderModal;