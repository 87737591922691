import React from "react";
import Feature from "./Feature";


const FeatureList = () => {
    const features = [
        {icon: 'bi bi-graph-up', title: 'Statistiques', inversed: false, desc: "Découvrez l'évolution de votre entreprise" },
        {icon: 'bi bi-currency-dollar', title: 'Caisse', inversed: true, desc: "Total et historique des mouvements de votre argent"},
        {icon: 'bi bi-person-lines-fill', title: 'Tâches', inversed: false, desc: "Suivre et affecter des tâches à vos équipes"},
        {icon: 'bi bi-people', title: 'Equipe', inversed: true, desc: "Contacter, gérer votre équipe"},
        {icon: 'bi bi-phone', title: 'Application mobile', inversed: false, desc: "Accessiblité sur votre téléphone"},
        {icon: 'bi bi-list-check', title: 'Fonctionnalités modulables', inversed: true, desc: "Ajustés selon votre besoin"},
    ]

    return (
        <>
            <div id="feature" style={{background: 'inherit', height: '100px', width: '100%'}}/>
            <div className="features-container">
                <div className="title-container">
                    <div>
                        <h2>
                            <span className="purple-color">Services de bases</span> proposés
                        </h2>
                        <div className="feature-item">
                            <p>** D'<span className="purple-color">autres fonctionnalités</span> sont disponibles selon votre type de société</p>
                            <p>** Exemples de <span className="purple-color">types de société</span>: boutique, formation, ...</p>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    {features.map((feature, index) => 
                    <div className="grid-item" key={index}>
                        <div>
                            <Feature 
                                icon={feature.icon} 
                                title={feature.title} 
                                inverted={feature.inversed}
                                desc={feature.desc}
                            />
                        </div>
                    </div>
                )}
                </div>
                <div id="about"/>
            </div>
        </>

    )
}

export default FeatureList;