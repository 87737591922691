
import React from "react";
import "../../assets/css/client/home/offerBox.css"

const OfferBox = ({ data, choose }) => {
    
    return (
        <>
            <div className="offer__card">
                <div className="header">
                    <h2>{data.title}</h2>
                </div>
                <div className="body">
                    <ul>
                        {data.details.map(detail => 
                            <li key={detail.id}> <i className="bi bi-check-circle"></i> {detail.label}</li>
                        )}
                    </ul>
                </div>
                <div className="footer">
                    <button className="asako-btn asako-btn-primary" onClick={choose}>Commander</button>
                </div>
            </div>
        </>
    )

}

export default OfferBox