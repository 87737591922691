import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import orderService from "services/orderService";
import productService from "services/productService";
import teamService from "services/teamService";

const OrderFilter = ({filtered}) => {
    const {projectId} = useParams();
    const [filters, setFilters] = useState({
        client: '',
        member: undefined,
        product: undefined,
        delivery_date: '',
        start_delivery_date: '',
        end_delivery_date: '',
        location: '',
        states: '',
        is_paid: ''
    });
    const [options, setOptions] = useState({
        members: [],
        products: [],
        status: []
    });
    const [tempValue, setTempValue] = useState({
        client: '' ,
        location: '',
    });

    const loadOptions = async () => {
        try {
            const [members, orderStatus, products] = await Promise.all([
                teamService.getMemberByProjectId(projectId),
                orderService.getOrderStatus(projectId),
                productService.getAllProduct(projectId)
            ]);
    
            setOptions({
                members: members,
                products: products,
                status: orderStatus
            });
        } catch (error) {
            console.error(error);
        }
    }

    // const handleChangeInputClient = useCallback(debounce((val) => {
    //     setFilters({...filters, client: val});
    // }, 500), []);

    const debounceFilter = useCallback(debounce((name, value)=>{
        setFilters({...filters, [name]: value});
    }, 500), []);

    const handleChangeInputWithDebouncing = (e) => {
        const {name, value} = e.target;
        setTempValue({
            ...tempValue,
            [name]: value
        });
        debounceFilter(name, value);
    }
    const handleDeleteFilters = () => {
        setFilters({
            client: '',
            member: undefined,
            product: undefined,
            delivery_date: '',
            start_delivery_date: '',
            end_delivery_date: '',
            location: '',
            states: undefined,
        });
    }

    useEffect(() => {
        loadOptions();
    }, []);

    useEffect(() => {
        filtered(filters);
    }, [filters]);

    return (
        <>
            <Card className="order-filter mb-1">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="filterFormClientName">
                                <Form.Label>
                                    Client
                                </Form.Label>
                                <Form.Control
                                    name="client"
                                    type="search"
                                    placeholder="Nom ou contact du client"
                                    value={tempValue.client}
                                    onChange={handleChangeInputWithDebouncing}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group controlId="filterFormProduct">
                                <Form.Label>
                                    Produit
                                </Form.Label>
                                <Form.Select
                                value={filters.product}
                                className="form-control"
                                onChange={e => setFilters({...filters, product: e.target.value})}
                                >
                                    <option value={''}>Tous les produits</option>
                                    {options.products.map(product=> 
                                        <option key={product.id} value={product.name}>{product.name}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="filterFormLivraison">
                                <Form.Label>
                                    Date et lieu de livraison
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="date"
                                        placeholder="JJ/MM/YYYY"
                                        value={filters.delivery_date}
                                        onChange={e => setFilters({...filters, delivery_date: e.target.value})}
                                    />
                                    <Form.Control
                                        name="location"
                                        placeholder="lieu"
                                        value={tempValue.location}
                                        onChange={handleChangeInputWithDebouncing}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="filterFormProduct">
                                <Form.Label>
                                    Responsable
                                </Form.Label>
                                <Form.Select
                                value={filters.member}
                                className="form-control"
                                onChange={e => setFilters({...filters, member: e.target.value})}
                                >
                                    <option value={''}>Tous les membres</option>
                                    {options.members.map(member => 
                                        <option key={member.id} value={member.id}>{member.first_name} {member.last_name}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={2}>
                            <Form.Group controlId="filterFormProduct">
                                <Form.Label>
                                    Status
                                </Form.Label>
                                <Form.Select
                                value={filters.states}
                                className="form-control"
                                onChange={e => setFilters({...filters, states: e.target.value})}
                                >
                                    <option value={''}>Tous les status</option>
                                    {Object.entries(options.status).map(([key, value]) =>
                                        <option value={key} key={key}>{value}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group controlId="filterFormPaid">
                                <Form.Label>
                                    Status du paiement
                                </Form.Label>
                                <Form.Select
                                value={filters.statesis_paid}
                                className="form-control"
                                onChange={e => setFilters({...filters, is_paid: e.target.value})}
                                >
                                    <option value={''}>Payé et non-payé</option>
                                    <option value={1}>Payé</option>
                                    <option value={0}>Non-payé</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={2} style={{display: 'flex'}}>
                            <Button variant="secondary" onClick={handleDeleteFilters}>Effacer les filtres</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}
export default OrderFilter;