import React from "react"

const ContactPhone = () => {
    return (
        <>
            <div className="phone">
                <p>Nous sommes à votre écoute pour répondre à toutes vos <span className="purple-color">questions</span>  ou demandes d'<span className="purple-color">informations</span></p>
                <ul>
                    <li> <i className="bi bi-telephone-fill"></i> +261 34 61 112 63 / +261 32 69 864 11</li>
                    <li> <i className="bi bi-facebook"></i> Rna Dev</li>
                    <li> <i class="bi bi-envelope-at-fill"></i> asako@rna-dev.mg</li>
                </ul>
            </div>
        </>
    )
}
export default ContactPhone