import React, { useEffect, useRef, useState } from "react";
import { useTask } from "contexts/TaskContext";
import taskService from "services/taskService";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";


const CardMoveOption = ({ card, close, move, cardIndex, columnIndex}) => {
    const moveRef = useRef(null);
    const { columns } = useTask();
    const { projectId } = useParams();
    const [column, setColumn] = useState({
        nb_card: 0,
    });
    const [formData, setFormData] = useState({
        column: card.task_column_id,
        position: card.position
    });

    useEffect(() => {
        taskService.getTaskColumnById(projectId, formData.column)
        .then(result => {
            setColumn(result)
            moveRef.current.scrollIntoView({ behavior: "smooth"});
        })
        .catch(error => console.error(error));
    }, [formData.column, card.task_column_id, projectId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleChangeColumn = (event) => {
        setFormData({
            column: event.target.value,
            position: sameColumn(event.target.value) ? card.position : 1
        });
    }
    const sameColumn = (columnId) => Number(columnId) === card.task_column_id;

    const handleSubmit = (event) => {
        event.preventDefault();
        move(columnIndex, cardIndex, formData.position, formData.column);
        const data = {
            column: formData.column,
            position: formData.position
        }
        taskService.moveCard(data, projectId, card.task_column_id, card.id)
        .then(() => {
            close();
        })
        .catch(error => console.error(error));
    }

    return (
        <>
            <div className="option-modal card-option">
                <div className="header">
                    <h6>Listes des actions</h6>
                    <div className="close-icon">
                        <i className="bi bi-x" onClick={close}></i>
                    </div>
                </div>
                <hr />
                <div className="column-option-container">
                    <Form onSubmit={handleSubmit}>
                        <div className="move-container">
                            <div className="form-group">
                                <label htmlFor="list-column">
                                    <i className="bi bi-distribute-horizontal"></i> Colonnes
                                </label>
                                <select 
                                onChange={handleChangeColumn}
                                value={formData.column}
                                name="column" 
                                id="list-column" 
                                placeholder="Colonnes"
                                className="list">
                                    <option value="" disabled>Colonne</option>
                                    {columns.map(column => <option key={column.id} value={column.id}>{column.id === card.task_column_id ? `${column.title} (Actuel)` : column.title}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="list-column">
                                    <i className="bi bi-distribute-vertical"></i> Position
                                </label>
                                <select
                                name="position"
                                value={formData.position}
                                onChange={handleChange}
                                id="list-position"
                                placeholder="Position"
                                className="position">
                                    <option value="" disabled>Position</option>
                                    {Array.from({ length: sameColumn(formData.column) ? column.nb_card : column.nb_card + 1 }, (_, cardPosition) => (
                                    <option key={cardPosition} value={cardPosition + 1}>{cardPosition + 1} {(cardPosition + 1 === Number(card.position) && card.task_column_id === formData.column) && '(actuel)'}</option>
                                    ))}
                                </select>
                            </div>
                            <button ref={moveRef} type="submit" className="asako-btn-primary asako-btn">Déplacer</button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default CardMoveOption;