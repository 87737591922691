import React from "react"

import { useEffect, useState } from "react"
import OfferBox from "./OfferBox"
import offerService from "services/offerService"
import UserInfoModal from "./UserInfoModal"
import OrderSendedModal from "./OrderSendedModal"

const Offer = () => {

    const [offers, setOffers] = useState([]);
    const [onOrder, setOrder] = useState(false);
    const [showSendedModal, setShowSendedModal] = useState(false);
    const [choosedOfferId, setChoosedOfferId] = useState(undefined);

    useEffect(() => {
        offerService.getAllOffers()
        .then(result => setOffers(result))
        .catch(error => console.error(error));
    }, [])

    const handleChooseOffer = (offerId) => {
        setChoosedOfferId(offerId);
        setOrder(true);
    }

    const handleCloseChoosingModal = () => {
        setOrder(false);
        setChoosedOfferId(undefined);
    }

    const handleOrderSended = () => {
        handleCloseChoosingModal();
        setShowSendedModal(true);
    }

    return (
        <>
            <div id="offer" style={{background: 'inherit', height: '100px', width: '100%'}}/>
            <div className="offers">
                {offers.map(offer => 
                    <OfferBox data={offer} key={offer.id} choose={() => handleChooseOffer(offer.id)}/>
                )}
                <UserInfoModal 
                show={onOrder} 
                offerId={choosedOfferId}
                close={() => handleCloseChoosingModal()}
                sended={() => handleOrderSended()}
                />
                <OrderSendedModal show={showSendedModal} close={() => setShowSendedModal(false)}/>
            </div>
        </>
    )

}
export default Offer

