import getAxiosInstance from "config/axiosConfig";

const profilService = {
    /**
     * Récupère tous les profiles utilisées dans l'application (admin, simple-user)
     * 
     * @returns Listes des profiles dans l'application
     */
    getAllProfil: async () => {
        const response = await getAxiosInstance().get('/v1/profils');
        return response.data;
    }
}

export default profilService;