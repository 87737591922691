import { useState } from "react";
import React from "react";
import ContactEmail from "./ContactEmail";
import ContactPhone from "./ContactPhone";
import { Tab, Tabs } from "react-bootstrap";
import "../../assets/css/client/home/contact.scss";

const Contact =() => {
    
    const [key, setKey] = useState('contact-mail');
    const isActive = (tabKey) => 'contact-'+tabKey === key;

    return (
        <>
            <div className="contact-container">
                <div className="card">
                    <div className="title">
                        <h3>Nous <span>Contacter</span></h3>
                    </div>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 tabs"
                    >
                        <Tab eventKey="contact-mail" title="Email" tabClassName={isActive('mail') ? 'active' : undefined}>
                            <div className="tab-container">
                                <div className="caption">
                                    <img src="/assets/img/email.png" alt="mail"/>
                                </div>
                                <ContactEmail />
                            </div>
                        </Tab>
                        <Tab eventKey="contact-phone" title="Téléphone & Réseaux" tabClassName={isActive('phone') ? 'active' : undefined}> 
                            <div className="tab-container">
                                <div className="caption">
                                    <img src="/assets/img/question.png" alt="mail"/>
                                </div>
                                <ContactPhone />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <div id="contact"/>
        </>
    )

}
export default Contact