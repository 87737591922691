import React, { createContext, useContext, useState } from 'react';
import clientService from 'services/clientService';
import projectService from 'services/projectService';

const ProfilContext = createContext();

export const ProfilProvider = ({ children }) => {
  const PROFIL_ADMIN_ID = 1;

  const [onCheckAdmin, setOnCheckAdmin] = useState(false);

  const [profil, setProfil] = useState({
    id: undefined,
    name: undefined
  });


  const setProjectId = (projectId) => {
    sessionStorage.setItem('AsakoProject', projectId);
  }

  const getProjectId = () => {
    return sessionStorage.getItem('AsakoProject');
  }

  const [userInfo, setUserInfo] = useState({
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    mail: undefined,
  });

  const loadUser = async () => {
    if (userInfo.id === undefined && clientService.connected()) {
      try {
        const result = clientService.me();
        clientService.me()
        .then(result => setUserInfo(result))
        .catch(error => console.error(error));
        return result;
      } catch (error) {
        console.error("Erreur lors du chargement de l'utilisateur:", error);
      }
    }
    return userInfo;
  };

  const isMe = async (user_id) => {
    return user_id === userInfo.id;
  }

  const isAdmin = async (project_id) => {
    try {
      const checkUserProfile = async (projId) => {
        setOnCheckAdmin(true);
        try {
          const user = await loadUser();
          const result = await projectService.getUserProfil(user.id, projId);
          
          setProfil({
            id: result.profil_id,
            name: result.profil_name
          });
          return result.profil_id === PROFIL_ADMIN_ID;
        } catch (error) {
          console.error(error);
          return false;
        } finally {
          setOnCheckAdmin(false);
        }
      };
  
      if (profil.id === undefined) {
        if (!onCheckAdmin) {
          return await checkUserProfile(project_id);
        } else {
          // Attendre que onCheckAdmin soit false
        while (onCheckAdmin) {
          await new Promise(resolve => setTimeout(resolve, 100)); // Attendre 100ms
        }
        return profil.id === PROFIL_ADMIN_ID;
        }
      }
  
      return profil.id === PROFIL_ADMIN_ID;
    } catch (error) {
      console.error(error);
      return false; 
    }
  };
  
  
  


  const isThisProfilAdmin = (profil_id) => {
    return profil_id === PROFIL_ADMIN_ID;
  }

  return (
    <ProfilContext.Provider value={{ profil,isThisProfilAdmin, setProfil, isAdmin, userInfo, setUserInfo, isMe, setProjectId, getProjectId }}>
      {children}
    </ProfilContext.Provider>
  );
};

export const useProfil = () => useContext(ProfilContext);
