import axiosNotAuthentified from "config/axiosNotAuthentified"
import ValidationError from "errors/ValidationError";

export const contactService = {
    sendQuestion: async (mail, question) => {
        try {
            const data = {
                mail: mail,
                question: question
            };
            await axiosNotAuthentified.post(`v1/contact`, data);
        } catch (error) {
            const response = error.response;
            if (response) {
                switch (response.status) {
                    case 422:
                        throw new ValidationError(response.data.errors);
                    default:
                        throw error;
                }
            }
        }   
    }
}