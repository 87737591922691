import React from "react";
import { Nav } from "react-bootstrap";
import "../../assets/css/client/home/sub-header.scss"


const SubHeader = () => {
    return (
        <div className="subHeader">
            <div className="menu-left">
                <h2>La solution <span className="purple-color">sur mesure</span> pour <br/> piloter votre entreprise avec efficacité</h2>
                <div className="features">
                    <span>
                        <i class="bi bi-graph-up"></i>
                        Statistiques
                    </span>
                    <span>
                        <i class="bi bi-currency-dollar"></i>
                        Caisse
                    </span>
                    <span>
                        <i class="bi bi-person-lines-fill"></i>
                        Tâches
                    </span>
                    <span>
                        <i class="bi bi-people"></i>    
                        Equipe
                    </span>
                </div>
                <p className="add-feature">** Les fonctionnalités sont <span className="purple-color">modulables</span> en fonction de vos besoins</p>
                <div className="action">
                    <Nav.Link className="link" href="#offer">
                    <button className="asako-btn asako-btn-primary-reverse"  href="#contact">Commander</button>
                    </Nav.Link>
                    <Nav.Link className="link" href="#offer">
                        <button className="asako-btn asako-btn-primary">Voir les offres</button>
                    </Nav.Link>
                </div>
            </div>
            <img className="img-bg" src="../assets/img/explication.png" alt="login background"/>
        </div>
    )
}
export default SubHeader