import React from "react";
import { Modal } from "react-bootstrap";
import ProductOrderForm from "./ProductOrderForm";

const UpdateProductOrderModal = ({show, close, updated, oldData}) => {

    return (
        <Modal show={show} centered className="asako__modal">
            <Modal.Header>
                <h2>Modifier un produit</h2>
                <i className="bi bi-x-lg" onClick={() => close()}></i>
            </Modal.Header>
            <Modal.Body>
                <ProductOrderForm submit={updated} oldData={oldData}/>
            </Modal.Body>
        </Modal>
    );
}
export default UpdateProductOrderModal;