import getAxiosInstance from "config/axiosConfig";


const cashingService = {

    isInflow: (cashMovement) => {
        return cashMovement.cash_movement_type_movement >= 10;
    },

    /**
     * Récupère les mouvements de caisse filtré d'un projet.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    getCashMovementFilteredByProject: async (projectId, filter, page = 1) => {
        const params = { 
            cash_registers: filter.cash_registers,
            cash_movement_types: filter.cash_movements_types,
            start_date: filter.start_date,
            end_date: filter.end_date,
            page: page,
        };
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/cash-movements/filtered`, {
            params: params
        });
        return response.data;
    },

    /**
     * Récupère le total de sortie, d'entré et le bénéfice brutes du projet.
     * 
     * @param {Int16Array} projectId L'identifiant du projet.
     * @returns project_id|total_inflow|total_outflow|totao_profit.
     */
    getProjectFlow: async (projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/flow`);
        return response.data;
    },


    /**
     * Récupère la liste des caisses avec leurs balance, le total d'entré, le total de sortie respectifs.
     * 
     * @param {Int16Array} projectId L'identifiant du projet.
     * @param {Array} filter Les filtres .
     * @param {Int16Array} page la page.
     * @returns Array CashRegisters Paginated.
     */
    getCashRegistersFlowByProjectAndName: async (projectId, filter, page = 1) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/cash-registers-flow`, {
            params: {
                order: filter.order,
                orderBy: filter.orderBy,
                page: page
            }
        });
        return response.data;
    },

    /**
     * Récupères tous les options nécessaires à l'écriture d'un mouvement de caisse.
     * 
     * @param {Int16Array} projectId l'identifiant du projet.
     * @returns members|suppliers|cashRegisters|cashMovementType
     */
    getCashMovementsOptionsByProject: async (projectId) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/cash-movements/options`);
        return response.data;
    },

    /**
     * Récupère les mouvements de caisse d'un projet.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    getCashMovementByProject: async (projectId, page = 1) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/cash-movements`, {
            params: { page: page }
        });
        return response.data;
    },

    /**
     * Récupère les mouvements dans une caisse d'un projet.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    getCashMovementByCashRegister: async (projectId, cashRegisterId, page = 1) => {
        const response = await getAxiosInstance().get(`v1/projects/${projectId}/cash-registers/${cashRegisterId}/cash-movements`, {
            params: { page: page }
        });
        return response.data;
    },

    /**
     * Met à jour le mouvements de caisse.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    updateCashMovement: async (data, projectId, cashMovementId) => {
        await getAxiosInstance().put(`v1/projects/${projectId}/cash-movements/${cashMovementId}`, data);
    },
    /**
     * Ajout un nouveau mouvement de caisse.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    addCashMovement: async (data, projectId) => {
        const response = await getAxiosInstance().post(`v1/projects/${projectId}/cash-movements`, data);
        return response.data;
    },
    /**
     * Supprime le mouvement de caisse d'un projet.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @returns une liste des mouvements de caisse du projet
     */
    deleteCashMovement: async (projectId, cashMovementId) => {
        await getAxiosInstance().delete(`v1/projects/${projectId}/cash-movements/${cashMovementId}`);
    },


}
export default cashingService;