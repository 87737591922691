import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MemberCard from "components/teams/MemberCard";

import { Col, Row } from "react-bootstrap";

import "../../assets/css/teams/members-list.scss";
import NewMemberModal from "components/teams/NewMemberModal";
import teamService from "services/teamService";
import utilService from "services/utilService";
import profilService from "services/profilService";
import { useProfil } from "contexts/ProfilContext";
import MemberLoader from "components/teams/MemberLoader";

const MembersListPage = () => {
    const { isAdmin } = useProfil();
    const { projectId } = useParams();
    const [members, setMembers] = useState([]);
    const [onAddLoad, setOnAddLoad] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        isAdmin(projectId)
        .then(result => setAdmin(result))
        .catch(error => console.log(error));
    }, [projectId, isAdmin]);

    // Les différents options pour les selects
    const [optionData, setOptionData] = useState({
        roles: [],
        profils: []
    });
    const loadData = useCallback(() => {
        teamService.getMemberByProjectId(projectId)
        .then(result => {
            setMembers(result);
        })
        .catch(error => console.error(error))
        .finally(() => setOnLoad(false));
    }, [projectId]);
    
    useEffect(() => {
        loadData();
    }, [loadData]);

    
    useEffect(() => {
        Promise.all([
            teamService.getAllRole(),
            profilService.getAllProfil()
        ])
        .then(([roles, profils]) => {
            setOptionData({
                roles: utilService.configureRoleOption(roles),
                profils: utilService.configureRoleOption(profils)
            })
        })
        .catch(error => console.error(error));
    }, [projectId]);


    return (
        <>
            <div className="members-list-container">
                <div className="row page-title-header">
                    <div className="col-12">
                        <div className="page-header">
                            <h1>Listes des <span style={{color: 'var(--primary-color)'}}>responsables</span></h1>
                            {admin && 
                            <NewMemberModal projectId={projectId} loadData={loadData} optionData={optionData} onLoad={setOnAddLoad}/>
                            }
                        </div>
                    </div>
                </div>
                <Row>
                    {onLoad && 
                        <>
                            {Array(6).fill(null).map((_, index) => (
                            <Col md={3} key={index}><MemberLoader /></Col>
                            ))}
                        </>
                    }
                    {members.map(member => 
                    <Col md={3} key={member.id}>
                        <MemberCard member={member} loadData={loadData} optionData={optionData}/>
                    </Col>
                    )}
                    {onAddLoad && <Col md={3}><MemberLoader/></Col>}
                </Row>
            </div>
        </>
    )
}
export default MembersListPage;