import React, { useCallback, useEffect, useState } from "react";      
import { Table } from "react-bootstrap";
import NewCashMovementTypeModal from "./NewCashMovementTypeModal";
import { useParams } from "react-router-dom";
import cashMovementTypeService from "services/cashMovementTypeService";
import ModifyCashMovementTypeModal from "./ModifyCashMovementTypeModal";
import { cashMovementTypeDict } from "utils/dict";
import AsakoPagination from "components/AsakoPagination";

const CashMovementTypeCRUD = () => {
    const { projectId } = useParams();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [cashMovementTypeData, setCashMovementTypeData] = useState([]);


    const loadData = useCallback(() => {
        cashMovementTypeService.getPaginatedCashMovementTypeByProject(projectId, page)
        .then(result => {
            setCashMovementTypeData(result.data);
            setPagination(result);
        })
        .catch(error => console.error(error));
    }, [projectId, page]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <>
            <h2>Listes des types de mouvements de caisse</h2>
            <Table striped className="cash-movement-type">
                <thead>
                    <tr>
                        <th className="index">#</th>
                        <th className="name">Nom</th>
                        <th className="movement">Mouvement</th>
                        <th className="action"><NewCashMovementTypeModal loadData={loadData} /></th>
                    </tr>
                </thead>
                <tbody>
                    {cashMovementTypeData.map((cashMovementType, index) => 
                    <tr key={cashMovementType.id}>
                        <td className="index">{(pagination.per_page * (pagination.current_page - 1)) + index + 1}</td>
                        <td className="name">{cashMovementType.name}</td>
                        <td className="movement">{cashMovementTypeDict(cashMovementType.movement)}</td>
                        <td className="action">
                            <ModifyCashMovementTypeModal loadData={loadData} cashMovementType={cashMovementType}/>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
            <AsakoPagination page={page} setPage={setPage} pagination={pagination}/>
        </>
    );
}
export default CashMovementTypeCRUD;