import React from "react";

const ColumnOptionModal = ({ close, setShowOption, removeColumn }) => {
    
    return (
        <div className="option-modal">
            <div className="header">
                <h6>Listes des actions</h6>
                <div className="close-icon">
                    <i className="bi bi-x" onClick={close}></i>
                </div>
            </div>
            <hr />
            <div className="column-option-container">
                <ul>
                    <li className="item" onClick={()=> setShowOption("move-column-option")}>
                        Déplacer
                    </li>
                    <li className="item" onClick={removeColumn}>
                        Archiver
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ColumnOptionModal;