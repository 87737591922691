import React, { useState } from "react";
import { Alert, Card, Col, Row, Table } from "react-bootstrap";
import '../../../assets/css/projects/clients/new-order-page.scss';
import AddProductOrderModal from "components/projects/clients/AddProductOrderModal";
import OrderForm from "components/projects/clients/OrderForm";
import UpdateProductOrderModal from "components/projects/clients/UpdateProductOrderModal";
import orderService from "services/orderService";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ValidationError from "errors/ValidationError";
import RemoveProductOrderModal from "components/projects/clients/RemoveProductOrderModal";
import { showError } from "utils/error";

const NewOrderPage = () => {
    const history = useHistory();
    const {projectId} = useParams();
    const [show, setShow] = useState({
        add: false,
        update: false,
        delete: false
    });
    const [formData, setFormData] = useState({
        products: [],
        client_name: '',
        client_facebook_link: '',
        contact: '',
        delivery_date: '',
        location: '',
        notice: '',
        member_id: undefined,

    });
    const [errors, setErrors] = useState({});
    const [selected, setSelected] = useState(undefined);

    const handleSubmit = async (data) => {
        try {
            const result = await orderService.addOrder({...formData, ...data}, projectId);
            history.push(`/projects/${projectId}/orders/${result.id}`);
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors(error.fields);
            }
            throw error;
        }
    }

    const handleAddProduct = async (product) => {
        const tempProduct = [...formData.products];
        tempProduct.push(product);
        setFormData({...formData, products: tempProduct});
        setShow({...show, add: false});
    }

    const handleUpdateProduct = async (product) => {
        const tempProduct = [...formData.products];
        tempProduct.splice(selected, 1, product);
        setFormData({...formData, products: tempProduct});
        setSelected(undefined);
        setShow({...show, update: false});
    }

    const handleDeleteProduct = async () => {
        const tempProduct = [...formData.products];
        tempProduct.splice(selected, 1);
        setFormData({...formData, products: tempProduct});
        setSelected(undefined);
        setShow({...show, delete: false});
    }

    const handleOpenUpdateModal = (index) => {
        setSelected(index);
        setShow({...show, update: true});
    }

    const handleOpenDeleteModal = (index) => {
        setSelected(index);
        setShow({...show, delete: true});
    }
    return (
        <div className="new-order-container">
            <AddProductOrderModal show={show.add} close={() => setShow({...show, add: false})} added={handleAddProduct}/>
            <UpdateProductOrderModal show={show.update} close={() => setShow({...show, update: false})} updated={handleUpdateProduct} oldData={formData.products[selected]}/>
            <RemoveProductOrderModal show={show.delete} close={() => setShow({...show, delete: false})} deleted={handleDeleteProduct} />
            <Row>
                <Col md={8}>
                    <Card className="product-card mb-4">
                        <Card.Header>
                            <h2>Les produits à commander</h2>
                            <button className="asako-btn asako-btn-primary" onClick={() => setShow({...show, add: true})}>
                                Ajouter un produit
                            </button>
                        </Card.Header>
                        <Card.Body>
                            <ul>
                                <li><strong>Q: </strong>Quantité</li>
                                <li><strong>PT: </strong>Prix Total</li>
                                <li><strong>PU: </strong>Prix Unitaire</li>
                                <li><strong>Devise: </strong>Ariary (Ar)</li>
                            </ul>
                            <Table striped className="new-order-table" bordered>
                                <thead>
                                    <tr>
                                        <th className="action">#</th>
                                        <th className="product">Produit</th>
                                        <th className="type">Type</th>
                                        <th className="unit_price">PU</th>
                                        <th className="quantity" style={{textAlign: 'right'}}>Q</th>
                                        <th className="details">Détails</th>
                                        <th className="total" style={{textAlign: 'right'}}>PT</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5} />
                                        <td style={{textAlign: 'right'}}><strong>TOTAL</strong></td>
                                        <td style={{textAlign: 'right'}}><strong>{orderService.getOrderTotal(formData).toLocaleString()}</strong></td>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {formData.products.length === 0 && 
                                    <tr>
                                        <td colSpan={7} style={{textAlign: 'center'}}>Aucun produit encore définie</td>
                                    </tr>
                                    }
                                    {formData.products.map((product, index) =>
                                    <tr key={index}>
                                        <td className="action">
                                            <div className="asako-btn-group">
                                                <button className="asako-btn-icon asako-btn-primary" onClick={() => handleOpenUpdateModal(index)}>
                                                    <i className="bi bi-pencil-fill"></i>
                                                </button>
                                                <button className="asako-btn-icon asako-btn-danger" onClick={() => handleOpenDeleteModal(index)}>
                                                    <i className="bi bi-trash-fill"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="product">{product.name}</td>
                                        <td className="type">{product.product_type}</td>
                                        <td className="unit_price">{Number(product.unit_price).toLocaleString()}</td>
                                        <td className="quantity" style={{textAlign: 'right'}}>{product.quantity.toLocaleString()}</td>
                                        <td className="details">
                                        <div
                                            dangerouslySetInnerHTML={{__html: product.description}}
                                        />
                                        </td>
                                        <td className="total" style={{textAlign: 'right'}}><strong>{orderService.getProductOrderTotal(product).toLocaleString()}</strong></td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {!!showError(errors, 'products') && 
                    <Alert variant="danger">
                        {showError(errors, 'products')}
                    </Alert>
                    }
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Header>
                            <h2>Informations du client</h2>
                        </Card.Header>
                        <Card.Body>
                            <OrderForm submit={handleSubmit}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default NewOrderPage;