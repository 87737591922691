import FormRequired from "components/FormRequired";
import ValidationError from "errors/ValidationError";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import teamService, { getMemberFullName } from "services/teamService";

const OrderForm = ({submit, oldData}) => {
    const {projectId} = useParams();
    const [formData, setFormData] = useState({
        client_name: oldData?.client_name||'',
        contact: oldData?.contact||'',
        delivery_date: oldData?.delivery_date||'',
        location: oldData?.location||'',
        client_facebook_link: oldData?.client_facebook_link||'',
        notice: oldData?.notice||'',
        member_id: oldData?.member_id||undefined
    });
    const [errors, setErrors] = useState({});
    const [options, setOptions] = useState({members: []});
    const [onLoad, setLoad] = useState(false);

    useEffect(() => {
        teamService.getMemberByProjectId(projectId)
        .then(result => {
            setOptions({
                ...options,
                members: result
            })
        })
        .catch(error => console.error(error));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoad(true);
        try {
            await submit(formData);
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors(error.fields);
            }
        } finally {
            setLoad(false);
        }

    }

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };
    
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Contact<FormRequired/>
                </Form.Label>
                <Form.Control
                    placeholder="03x xx xxx xx"
                    value={formData.contact}
                    onChange={e => setFormData({...formData, contact: e.target.value})}
                    isInvalid={!!showError('contact')}
                    />
                <Form.Control.Feedback type="invalid">
                    {showError('contact')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Nom du client<FormRequired/>
                </Form.Label>
                <Form.Control
                placeholder="Nom du client"
                value={formData.client_name}
                onChange={e => setFormData({...formData, client_name: e.target.value})}
                isInvalid={!!showError('client_name')}
                />
                <Form.Control.Feedback type="invalid">
                    {showError('client_name')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Lien du compte facebook du client
                </Form.Label>
                <Form.Control
                placeholder="http://www.facebook.com/xxx"
                value={formData.client_facebook_link}
                onChange={e => setFormData({...formData, client_facebook_link: e.target.value})}
                isInvalid={!!showError('client_name')}
                />
                <Form.Control.Feedback type="invalid">
                    {showError('client_name')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Date de livraison<FormRequired/>
                </Form.Label>
                <Form.Control
                type="date"
                placeholder="JJ/MM/AAAA"
                value={formData.delivery_date}
                isInvalid={!!showError('delivery_date')}
                onChange={e => setFormData({...formData, delivery_date: e.target.value})}
                />
                <Form.Control.Feedback type="invalid">
                    {showError('delivery_date')}
                </Form.Control.Feedback>            
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Lieu de livraison<FormRequired/>
                </Form.Label>
                <Form.Control
                placeholder="Lieu de livraison"
                value={formData.location}
                onChange={e => setFormData({...formData, location: e.target.value})}
                isInvalid={!!showError('location')}
                />
                <Form.Control.Feedback type="invalid">
                    {showError('location')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Responsable<FormRequired/>
                </Form.Label>
                <Form.Select
                placeholder="Lieu de livraison"
                value={formData.member_id}
                onChange={e => setFormData({...formData, member_id: e.target.value})}
                className="form-control"
                isInvalid={!!showError('member_id')}
                >
                    <option >Responsable</option>
                    {options.members.map(member => 
                        <option key={member.id} value={member.id}>{getMemberFullName(member)}</option>
                    )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {showError('member_id')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="orderFormContact">
                <Form.Label>
                    Notes
                </Form.Label>
                <ReactQuill
                placeholder="Un commentaire ..."
                value={formData.notice}
                onChange={value => setFormData({...formData, notice: value})}
                isInvalid={!!showError('notices')}
                />
                <Form.Control.Feedback type="invalid">
                    {showError('notices')}
                </Form.Control.Feedback>            
            </Form.Group>
            <button type="submit" className="asako-btn asako-btn-primary" disabled={onLoad}>
                Passer Commande {onLoad && <Spinner size="sm"/>}
            </button>
        </Form>
    );
}
export default OrderForm;