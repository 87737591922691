import AddProductOrderModal from "components/projects/clients/AddProductOrderModal";
import RemoveProductOrderModal from "components/projects/clients/RemoveProductOrderModal";
import UpdateProductOrderModal from "components/projects/clients/UpdateProductOrderModal";
import React, { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import '../../../assets/css/projects/clients/order-detail-page.scss';
import orderService from "services/orderService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { changeDateFormat } from "utils/DateFormatter";
import UpdateOrderModal from "components/projects/clients/UpdateOrderModal";
import UnPaidModal from "components/projects/clients/UnpaidModal";

const OrderDetailPage = () => {
    const {projectId, orderId} = useParams();
    const [order, setOrder] = useState({
        contact: '',
        client_name: '',
        delivery_date: undefined,
        location: '',
        member_first_name: '',
        member_last_name: '',
        total_price: 0,
        products: [],
        notice: '',
        client_facebook_link: '',
    });
    const [options, setOptions] = useState({
        orderStatus: []
    });
    const [show, setShow] = useState({
        add: false,
        update: false,
        updateOrder: false,
        delete: false,
        unPaid: false
    });
    const [selected, setSelected] = useState(undefined);

    useEffect(() => {
        orderService.getOrderStatus()
        .then(result => {
            setOptions({
                ...options,
                orderStatus: result
            });
        })
        .catch(error => console.error(error));
    }, []);
    useEffect(() => {
        orderService.getOrderById(projectId, orderId)
        .then(result => {
            setOrder(result);
        })
        .catch(error => console.error(error));
    }, [])

    const handleAddProduct = async (product) => {
        try {
            const tempProduct = [...order.products];
            product.total_price = product.unit_price * product.quantity;
            product.state = 5;
            tempProduct.push(product);
            const tempOrder = {...order};
            tempOrder.products = tempProduct;
            await orderService.updateOrder(projectId, orderId, tempOrder);
            setOrder(tempOrder);
            setShow({...show, add: false});
        } catch (error) {
            console.error(error);
        } 
    }

    const handleUpdateProduct = async (product) => {
        try {
            const tempProduct = [...order.products];
            product.total_price = product.unit_price * product.quantity;
            tempProduct.splice(selected, 1, product);
            const tempOrder = {...order};
            tempOrder.products = tempProduct;
            setOrder(tempOrder);
            setSelected(undefined);
            await orderService.updateOrder(projectId, orderId, tempOrder);
            setShow({...show, update: false});

        } catch (error) {
            console.error(error);
        }
    }

    const handleDeleteProduct = async () => {
        try {
            const tempProduct = [...order.products];
            tempProduct.splice(selected, 1);
            const tempOrder = {...order};
            tempOrder.products = tempProduct;
            await orderService.updateOrder(projectId, orderId, tempOrder);
            setOrder(tempProduct);
            setSelected(undefined);
            setShow({...show, delete: false});
        } catch (error) {
            console.error(error);
        }
    }

    const handleOpenUpdateModal = (index) => {
        setSelected(index);
        setShow({...show, update: true});
    }

    const handleOpenAddModal = () => {
        setShow({...show, add: true});
    }

    const getOrderStatusColor = (product) => {
        const number = product.state;
        if (number >= 0 && number < 5) {
            return '#ff6258';
        } else if (number >= 5 && number < 10) {
            return '#ffaf00';
        } else if (number >= 10 && number < 15) {
            return '#8862e0';
        } else if (number >= 15 && number < 19) {
            return '#5E17EB';
        } else {
            return '#19d895';
        }
    }

    const handleChangeStatus = async (value, index) => {
        try {
            const tempProduct = [...order.products];
            tempProduct[index].state = value;
            const tempOrder = {...order};
            tempOrder.products = tempProduct;
            setOrder(tempOrder);
            await orderService.updateOrder(projectId, orderId, tempOrder);
        } catch (error) {
            console.error(error);
        }
    }

    const handleUpdateOrder = async (data) => {
        try {
            const tempOrder = {...order, ...data};
            await orderService.updateOrder(projectId, tempOrder.id, tempOrder);
            setOrder(tempOrder);
            setShow({...show, updateOrder: false});
            
        } catch (error) {
            console.error(error);
        }
    }

    const markAsPaid = async (paid = 0) => {
        try {
            const tempOrder = {...order, is_paid: paid === 0 ? 1 : 0};
            setOrder(tempOrder);
            await orderService.updateOrder(projectId, tempOrder.id, tempOrder);
        } catch (error) {
            console.error(error);
        }
    }


    const isPaid = (order) => {
        return order.is_paid !== 0;
    }
    
    
    return (
        <div className="order-detail-container">
            <UnPaidModal show={show.unPaid} close={() => setShow({...show, unPaid: false})} markAsPaid={markAsPaid} />
            <UpdateOrderModal show={show.updateOrder} close={()=> setShow({...show, updateOrder: false})} updated={handleUpdateOrder} oldData={order}/>
            <AddProductOrderModal show={show.add} close={() => setShow({...show, add: false})} added={handleAddProduct}/>
            <UpdateProductOrderModal show={show.update} close={() => setShow({...show, update: false})} updated={handleUpdateProduct} oldData={order.products[selected]}/>
            <RemoveProductOrderModal show={show.delete} close={() => setShow({...show, delete: false})} deleted={handleDeleteProduct} />
            
            <Card className="mb-4 order-info-card">
                <Card.Header>
                    <div style={{display: 'flex'}}>
                        <h2>
                            Information du client
                        </h2>
                        <div style={{display: "flex", alignItems: 'center', marginLeft: '20px'}}>
                            <Badge
                            style={{cursor: 'pointer'}}
                            onClick={() => 
                            isPaid(order) 
                            ? setShow({...show, unPaid: true})
                            : markAsPaid(order.is_paid)
                            }
                            pill
                            bg={isPaid(order) ? 'success' : 'danger'}
                            >
                            {isPaid(order) ? 'Payé' : 'Non Payé'}
                            </Badge>
                        </div>
                    </div>
                    <button className="asako-btn-icon asako-btn-primary" onClick={() => setShow({...show, updateOrder: true})}>
                        <i className="bi bi-pencil"></i>
                    </button>
                </Card.Header>
                <Card.Body>
                    <ul>
                        <li><h4><strong>Montant de la commande: </strong>{order.total_price.toLocaleString()} Ar</h4></li>
                        <li><strong>Nom du Client: </strong><a href={order.client_facebook_link} target="_blank" rel="noopener noreferrer">{order.client_name}</a></li>
                        <li><strong>Contact: </strong>{order.contact}</li>
                        <li><strong>Lieu de livraison: </strong>{order.location}</li>
                        <li><strong>Date de livraison: </strong>{changeDateFormat(order.delivery_date, 'll') }</li>
                        <li><strong>Remarque: </strong></li>
                        <div style={{borderLeft: '5px solid gray', paddingLeft: '10px'}} dangerouslySetInnerHTML={{__html: order.notice}}/>
                    </ul>
                </Card.Body>
            </Card>
            <Card className="mb-4">
                <Card.Header>
                    <h2>Les produits</h2>
                    <button className="asako-btn-icon asako-btn-primary" onClick={handleOpenAddModal}>
                        <i className="bi bi-plus-lg"></i>
                    </button>
                </Card.Header>
                <Card.Body>
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Étiquette</th>
                                <th>Produit</th>
                                <th>Q</th>
                                <th>PU</th>
                                <th>Description</th>
                                <th>PT</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.products.map((product, index) => 
                            <tr key={index}>
                                <td>
                                    <div className="asako-btn-group">
                                        <button className="asako-btn-icon asako-btn-primary" onClick={() => handleOpenUpdateModal(index)}>
                                            <i className="bi bi-pencil-fill"></i>
                                        </button>
                                    </div>
                                </td>
                                <td>{product.name}</td>
                                <td>{product.product_type}</td>
                                <td>{product.quantity.toLocaleString()}</td>
                                <td>{Number(product.unit_price).toLocaleString()}</td>
                                <td className="description">
                                    <div dangerouslySetInnerHTML={{__html: product.description}}/>
                                </td>
                                <td>{product.total_price.toLocaleString()}</td>
                                <td>
                                    <select
                                    value={product.state}
                                    className="order-status-select"
                                    style={{background: getOrderStatusColor(product)}}
                                    onChange={e => handleChangeStatus(e.target.value, index)}
                                    >
                                        {Object.entries(options.orderStatus).map(([value, label]) => 
                                            <option key={value} value={value}>{label}</option>
                                        )}
                                    </select>   
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
}
export default OrderDetailPage;