import { toast } from "react-toastify";

const notifyService = {


    success: async(message, config) => {
        toast.success(message || "");
    },

    error: async(message) => {
        toast.error(message || "");
    },

}

export default notifyService;