import moment from "moment";
import 'moment/locale/fr';

moment.locale('fr');

export const parseDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date, format);   
}

export const formatDate = (date, format = 'l') => {
    if (!date) return 'Date non valide';
    return parseDate(date).format(format);
}

export const changeDateFormat = (date, finalFormat= 'l', initFormat = 'YYYY-MM-DD') => {
    const parsedDate = moment(date, initFormat);
    return parsedDate.format(finalFormat);
}
