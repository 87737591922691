import React from "react";

import '../../assets/css/admin/project.scss';
import { useHistory } from "react-router-dom";
import ModifyProjectModal from "./ModifyProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import { useProfil } from "contexts/ProfilContext";
import { Card } from "react-bootstrap";

const Project = ({ data, loadData, projectIndex, remove, update }) => {
    const { setProfil, isThisProfilAdmin, setProjectId } = useProfil(); 
    const history = useHistory();
    const chooseProject = () => {
        setProfil({
            id: data.profil_id,
            name: data.profil,
        });
        setProjectId(data.id);
        history.push(`/projects/${data.id}/dashboard`);
    }

    const handleRemove = () => {
        remove(projectIndex);
    }

    const handleUpdate = (data) => {
        update(data, projectIndex)
    }

    return (
        <>
            <Card className="project">
                <Card.Header onClick={chooseProject} >
                    <h4>{data.name} ({data.project_type})</h4>
                </Card.Header>
                <Card.Body onClick={chooseProject} >
                    <div
                        dangerouslySetInnerHTML={{__html: data.description}}
                    />
                </Card.Body>
                <Card.Footer>
                    {isThisProfilAdmin(data.profil_id) &&
                    <>
                        <ModifyProjectModal data={data} loadData={loadData} update={handleUpdate}/>
                        <DeleteProjectModal data={data} remove={handleRemove}/>
                    </> 
                    }
                </Card.Footer>
            </Card>
        </>
    );
}

export default Project;