import React, { useEffect, useState } from "react";

import Navbar from '../templates/shared/Navbar';
import Sidebar from '../templates/shared/Sidebar';
import "../assets/css/admin/template.scss";
import SettingsPanel from "templates/shared/SettingsPanel";
import Footer from "templates/shared/Footer";
import { useProfil } from "contexts/ProfilContext";
import { unescape } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ProjectLayout = ({ children }) => {
    const [sidebarWidth, setSidebarWidth] = useState(270);
    const {getProjectId} = useProfil();
    const {projectId} = useParams();

    const needSidebar = () => {
        return !!getProjectId();
    }

    useEffect(() => {
        if (!needSidebar()) {
            setSidebarWidth(0);
        } else {
            setSidebarWidth(270);
        }
    }, [projectId]);

    const changeSize = () => {
        if (!needSidebar()) {
            setSidebarWidth(0);
            return;
        } else {
            const body = document.querySelector('body');
            if (body.classList.contains('sidebar-icon-only')) {
                setSidebarWidth(70);
            } else {
                setSidebarWidth(270);
            }
        }
    }

    return (
        <>
            <div className="container-scroller">
                <Navbar changeSize={changeSize} fullWidth={!needSidebar()}/>
                <div className="container-fluid page-body-wrapper">
                    {needSidebar() && <Sidebar setSidebarWidth={setSidebarWidth}/>}
                    <div className="main-panel" style={{marginLeft: sidebarWidth}}>
                        <div className="content-wrapper">
                            { children }
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProjectLayout;