import React, { useEffect, useState } from "react";
import FormSelect from "components/FormSelect";
import FormText from "components/FormText";
import cashingService from "services/cashingService";
import { useParams } from "react-router-dom";
import { showError } from "utils/error";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddCashMovementLine = ({ options, loadData }) => {
    const { projectId } = useParams();
    const [formData, setFormData] = useState({
        movement_date: new Date().toISOString().split('T')[0],
        member_id: undefined,
        cash_register_id: undefined,
        cash_movement_type_id: undefined,
        product: "",
        supplier_id: undefined,
        quantity: "",
        unit_price: "",
        total_inflow: 0,
        total_outflow: 0
    });
    const [errors, setErrors] = useState({});
    const [optionsMembers, setOptionsMembers] = useState([]);
    const [optionsSuppliers, setOptionsSuppliers] = useState([]);
    const [optionsCashRegisters, setOptionsCashRegisters] = useState([]);
    const [optionsCashMovementTypes, setOptionsCashMovemenTypes] = useState([]);
    const [cashMovementTypes, setCashMovementsTypes] = useState([]);
    const [addSupplier, setAddSupplier] = useState(true);

    useEffect(() => {
        setOptionsMembers(options.members.map(member => ({ label: `${member.first_name} ${member.last_name}`, value: member.id })));
        setOptionsSuppliers(options.suppliers.map(supplier => ({ label: supplier.name, value: supplier.id })));
        setOptionsCashRegisters(options.cash_registers.map(cashRegister => ({ label: cashRegister.name, value: cashRegister.id })));
        setOptionsCashMovemenTypes(options.cash_movements_types.map(cashMovementType => ({ label: cashMovementType.name, value: cashMovementType.id })));
        setCashMovementsTypes(options.cash_movements_types);
    }, [options]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => {
            const updatedFormData = {
                ...prevData,
                [name]: value,
            };
            if (["quantity", "unit_price", "cash_movement_type_id"].includes(name)) {
                return updateFlow(updatedFormData);
            }
            return updatedFormData;
        });
    };

    const handleAdd = () => {
        cashingService.addCashMovement(formData, projectId)
        .then(result => {
            loadData();
            initValue();
        })
        .catch(error => setErrors(error.response.data.errors));
    };

    const updateFlow = (updatedFormData) => {
        const cashMovementType = cashMovementTypes.find(
            (element) => element.id === Number(updatedFormData.cash_movement_type_id)
        );
        if (cashMovementType) {
            const isOutflow = cashMovementType.movement < 10;
            return {
                ...updatedFormData,
                total_inflow: isOutflow ? 0 : updatedFormData.quantity * updatedFormData.unit_price,
                total_outflow: isOutflow ? updatedFormData.quantity * updatedFormData.unit_price : 0,
            };
        }
        return updatedFormData;
    };

    const initValue = () => {
        setFormData({
            movement_date: new Date().toISOString().split('T')[0],
            member_id: undefined,
            cash_register_id: undefined,
            cash_movement_type_id: undefined,
            product: "",
            supplier_id: undefined,
            quantity: "",
            unit_price: "",
            total_inflow: 0,
            total_outflow: 0
        });
        setErrors({});
    };

    const handleChangeTypeMovement = (event) => {
        const value = event.target.value;
        const cashMovementType = cashMovementTypes.find(
            (element) => element.id === Number(value)
        );
        const canAddSupplier = cashMovementType?.movement < 10;
        setFormData(prevData => ({
            ...prevData,
            cash_movement_type_id: value,
            supplier_id: canAddSupplier ? prevData.supplier_id : null // Optional chaining to avoid potential errors
        }));
        setAddSupplier(canAddSupplier);
    }

    return (
        <>
            <td className="movement_date">
                <FormText
                    formClass="input"
                    type="date"
                    handleChange={handleChange}
                    name="movement_date"
                    value={formData.movement_date}
                    placeholder="Date de mouvement"
                    error={showError(errors, "movement_date")}
                />
            </td>
            <td className="responable">
                <FormSelect
                    formClass="input"
                    name="member_id"
                    value={formData.member_id || ""}
                    placeholder="Responsable"
                    handleChange={handleChange}
                    options={optionsMembers}
                    error={showError(errors, "member_id")}
                />
            </td>
            <td className="cash_register">
                <FormSelect
                    formClass="input"
                    name="cash_register_id"
                    value={formData.cash_register_id || ""}
                    placeholder="Caisse"
                    handleChange={handleChange}
                    options={optionsCashRegisters}
                    error={showError(errors, "cash_register_id")}
                />
            </td>
            <td className="type">
                <FormSelect
                    formClass="input"
                    name="cash_movement_type_id"
                    value={formData.cash_movement_type_id || ""}
                    placeholder="Type"
                    handleChange={handleChangeTypeMovement}
                    options={optionsCashMovementTypes}
                    error={showError(errors, "cash_movement_type_id")}
                />
            </td>
            <td className="product">
                <FormText
                    formClass="input"
                    name="product"
                    value={formData.product}
                    placeholder="Produit"
                    handleChange={handleChange}
                    error={showError(errors, "product")}
                />
            </td>
            <td className="supplier">
                <FormSelect
                    formClass="input"
                    name="supplier_id"
                    value={formData.supplier_id || ""}
                    placeholder="Fournisseur"
                    handleChange={handleChange}
                    options={optionsSuppliers}
                    error={showError(errors, "supplier_id")}
                    disabled={!addSupplier}
                />
            </td>
            <td className="quantity">
                <FormText
                    formClass="input"
                    name="quantity"
                    value={formData.quantity}
                    placeholder="Quantité"
                    handleChange={handleChange}
                    error={showError(errors, "quantity")}
                />
            </td>
            <td className="unit_price">
                <FormText
                    formClass="input"
                    name="unit_price"
                    value={formData.unit_price}
                    placeholder="Prix Unitaire"
                    handleChange={handleChange}
                    error={showError(errors, "unit_price")}
                />
            </td>
            <td className="total_inflow">
                <FormText
                    formClass="input"
                    name="total_inflow"
                    value={formData.total_inflow}
                    placeholder="Entrée total"
                    handleChange={handleChange}
                    disabled={true}
                />
            </td>
            <td className="total_outflow">
                <FormText
                    formClass="input"
                    name="total_outflow"
                    value={formData.total_outflow}
                    placeholder="Sortie Total"
                    handleChange={handleChange}
                    disabled={true}
                />
            </td>
            <td className="action">
                <div className="asako-btn-group">
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipValidate}
                    >
                        <button className="asako-btn-icon asako-btn-primary" onClick={() => handleAdd()}>
                            <i className="bi bi-check2-all"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipReset}
                    >
                        <button className="asako-btn-icon asako-btn-secondary" onClick={() => initValue()}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            </td>
        </>
    );
};

const tooltipValidate = (
    <Tooltip id="tooltip">
        Valider
    </Tooltip>
);

const tooltipReset = (
    <Tooltip id="tooltip">
        Tout effacer
    </Tooltip>
);

export default AddCashMovementLine;
