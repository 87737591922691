import React from "react";

import '../../assets/css/admin/project.scss';
import { Card } from "react-bootstrap";

const ProjectLoader = () => {

    return (
        <>
            <Card className="project loader">
                <Card.Header>
                    <div />
                </Card.Header>
                <Card.Body>
                    <div />
                    <div />
                    <div />
                    <div />
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        </>
    );
}

export default ProjectLoader;