const { default: getAxiosInstance } = require("config/axiosConfig")

const notificationService = {
    getUserNotification: async () => {
        try {
            const response = await getAxiosInstance().get('v1/notifications');
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
}

export default notificationService;