import React from "react";
import { Modal } from "react-bootstrap";

const OrderSendedModal = ({ show, close }) => {
    return (
        <Modal show={show} centered dialogClassName="asako__popup" onHide={close}>
            <Modal.Header>
                <Modal.Title>
                    Commande envoyé
                </Modal.Title>
                <i className="bi bi-x" onClick={close}></i>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Votre commande a été envoyé avec <span>succès</span>.
                </p>
                <p>
                    <span>Merci</span> de nous faire confiance. Nous prendrons contact avec vous dans un <span>petit délai</span>.    
                </p>
            </Modal.Body> 
        </Modal>
    );
}
export default OrderSendedModal;