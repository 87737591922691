import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import cashingService from "services/cashingService";
import AsakoPagination from "components/AsakoPagination";

const CashRegisterFlow = (loadData) => {
    const { projectId } = useParams();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [cashRegisterData, setCashRegisterData] = useState([]);

    const [filter, setFilter] = useState({
        order: "asc",
        orderBy: "name"
    });

    const handleChangeOrder = (field) => () => {
        setFilter({
            ...filter,
            order: field !== filter.orderBy ? 'asc' : filter.order === 'asc' ? 'desc' : 'asc',
            orderBy: field,
        });
    };

    useEffect(() => {
        cashingService.getCashRegistersFlowByProjectAndName(projectId, filter, page)
            .then(result => {
                setCashRegisterData(result.data);
                setPagination(result);
            })
            .catch(error => console.error(error));
    }, [page, filter, projectId, loadData]);



    return (
        <>
            <Card className="mb-4 cash-register-flow__card">
                <Card.Body>
                    <Card.Title>
                        <h4>Les Caisses du projet</h4>
                    </Card.Title>
                    <Table striped>
                        <thead>
                            <tr>
                                <th onClick={handleChangeOrder('name')} className="name">
                                    Caisse
                                    {filter.orderBy === 'name' ? (filter.order === 'asc' ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>) : ''}
                                </th>
                                <th onClick={handleChangeOrder('total_profit')} className="balance">
                                    Balance
                                    {filter.orderBy === 'total_profit' ? (filter.order === 'asc' ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-up-fill"></i>) : ''}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {cashRegisterData.length === 0 && <tr><td colSpan={2} style={{textAlign: 'center'}}>Aucun Caisse</td></tr>}
                            {cashRegisterData.map(cashRegister => 
                                <tr key={cashRegister.id}>
                                    <td className="name">{cashRegister.name}</td>
                                    <td className="balance">{`${cashRegister.total_profit.toLocaleString()} Ar`}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
                <AsakoPagination page={page} setPage={setPage} pagination={pagination} />
            </Card>
        </>
    );
};

export default CashRegisterFlow;
