import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import taskService from "services/taskService";

const TaskColumnMoveModal = ({ column, close, move }) => {
    const { projectId } = useParams();
    const [nbColumn, setNbColumn] = useState(0);
    const [formPosition, setFormPosition] = useState(column.position);

    useEffect(() => {
        taskService.getNbTaskColumn(projectId)
        .then(result => setNbColumn(result))
        .catch(error => console.error(error));
    }, [projectId]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            position: formPosition
        };
        taskService.moveColumn(data, projectId, column.id)
        .then(() => {
            move(column.position, formPosition);
        })
        .catch(error => console.error(error));
    }
    
    return (
        <div className="option-modal">
            <div className="header">
                <h6>Déplacer</h6>
                <div className="close-icon">
                    <i className="bi bi-x" onClick={close}></i>
                </div>
            </div>
            <hr />
            <div className="move-column-option-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor=""><i className="bi bi-arrows-expand-vertical"></i> Position</label>
                        <select name="" id="" onChange={(event) => setFormPosition(event.target.value)} value={formPosition}>
                        {Array.from({ length: nbColumn}, (_, index) => (
                            <option value={index + 1} key={index}>{index + 1 === column.position ? `${index + 1} (Actuel)` : index + 1}</option>
                        ))}
                        </select>
                    </div>
                    <button type="submit" className="asako-btn-primary">Déplacer</button>
                </form>
            </div>
        </div>
    )
}

export default TaskColumnMoveModal;