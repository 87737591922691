import ValidationError from "errors/ValidationError";
import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import offerService from "services/offerService";

const UserInfoForm = ({submit, offerId, sended}) => {
    const [formData, setFormData] = useState({mail: '', phone: '', adress: '', first_name: '', last_name:''})
    const [errors, setErrors] = useState({});
    const [onLoad, setLoad] = useState(false);

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoad(true);
            await offerService.orderAnOffer(formData, offerId);
            sended();
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors(error.fields);
            } else {
                console.error(error);
            }
        } finally {
            setLoad(false);
        }
    }

    return (
        <>
            <Form className="userinfo-form" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="UserInfoFormName">
                    <Form.Label>
                        <span> <i className="bi bi-person"></i> </span>
                        Nom
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    placeholder="Votre nom"
                    value={formData.first_name}
                    onChange={e => setFormData({...formData, first_name: e.target.value})}
                    isInvalid={!!showError('first_name')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('first_name')}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="UserInfoFormName">
                    <Form.Label>
                        <span> <i className="bi bi-person"></i> </span>
                        Prénom
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    placeholder="Votre prénom"
                    onChange={e => setFormData({...formData, last_name: e.target.value})}
                    value={formData.last_name}
                    isInvalid={!!showError('last_name')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('last_name')}
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group className="mb-3" controlId="UserInfoFormMail">
                    <Form.Label>
                        <span> <i className="bi bi-envelope"></i> </span>
                        Email
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    placeholder="Votre mail"
                    onChange={e => setFormData({...formData, mail: e.target.value})}
                    value={formData.mail}
                    isInvalid={!!showError('mail')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('mail')}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="UserInfoFormPhone">
                    <Form.Label>
                        <span> <i className="bi bi-telephone"></i> </span>
                        Téléphone
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    placeholder="Votre téléphone"
                    value={formData.phone}
                    onChange={e => setFormData({...formData, phone: e.target.value})}
                    isInvalid={!!showError('phone')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('phone')}
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="UserInfoFormAdress">
                    <Form.Label>
                        <span> <i className="bi bi-house"></i> </span>
                        Adresse
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    placeholder="Votre téléphone"
                    onChange={e => setFormData({...formData, adress: e.target.value})}
                    value={formData.adress}
                    isInvalid={!!showError('adress')}
                    />
                    <Form.Control.Feedback type="invalid">
                        {showError('adress')}
                    </Form.Control.Feedback>
                </Form.Group>
                
                <button className="asako-btn asako-btn-primary" disabled={onLoad}>
                    Commander {onLoad && <Spinner size="sm"/>}
                </button>
            </Form>
        </>
    );
}
export default UserInfoForm;