import React from "react";
import { Modal } from "react-bootstrap";
import OrderForm from "./OrderForm";

const UpdateOrderModal = ({show, close, updated, oldData}) => {
    
    return (
        <Modal centered show={show} onHide={()=>close()} className="asako__modal" size="xl">
            <Modal.Header>
                <Modal.Title>
                    Nouvelle Commande
                </Modal.Title>
                <i className="bi bi-x-lg" onClick={() => close()}></i>
            </Modal.Header>
            <Modal.Body>
                <OrderForm submit={(formData) => updated(formData)} oldData={oldData}/>
            </Modal.Body>
        </Modal>
    );
}
export default UpdateOrderModal;