import React, { useEffect, useState } from "react";  
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import dashboardService from "services/dashboardService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Dashboard = () => {
  const {projectId} = useParams();
  const [profitsOverview, setProfitsOverview] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [overview, setOverview] = useState({
    members: 0,
    orders: 0
  })
  const [years, setYears] = useState([]);

  useEffect(() => {
    Promise.all([
      dashboardService.getValideYear(projectId),
      dashboardService.getNbOrderInProject(projectId),
      dashboardService.getNbMemberInProject(projectId)
    ])
    .then(([yearsResult, ordersResult, membersResult]) => {
      setYears(yearsResult);
      setOverview(prev => ({
        ...prev,
        orders: ordersResult,
        members: membersResult
      }));
    })
    .catch(error => console.log(error));
  }, []);
  
  useEffect(() => {
    dashboardService.getProfitsGraphe(projectId, selectedYear)
    .then(result => setProfitsOverview(result))
    .catch(error => console.error(error));
  }, [selectedYear]);



  const marketingOverviewData= {
      labels: ["Janv", "Févr", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"],
      datasets: [{
          label: 'BÉNÉFICE',
          data: profitsOverview.map(item => item.total_profit > 0 ? item.total_profit : 0),
          backgroundColor: '#4709c2',
          borderColor: '#4709c2',
          borderWidth: 0
        },
        {
          label: 'PERTE',
          data: profitsOverview.map(item => item.total_profit < 0 ? item.total_profit : 0),
          backgroundColor: '#DC3545',
          borderColor: '#DC3545',
          borderWidth: 0
        }
      ]
  };
  const profitOverviewOptions= {
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0
        }
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            let value = tooltipItem.yLabel; 
            return value.toLocaleString() + ' Ariary';
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            max: 1_000_000,
            min: -500_000,
            display: true,
            beginAtZero: false,
            fontColor: "#b9b8b8",
          },
          gridLines: {
            display: true,
            color: "#dde4eb",
            zeroLineColor: "#111"
          }
        }],
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#b9b8b8",
            color: "#dde4eb",
          },
          gridLines: {
            display: true,
            color: "#dde4eb",
            zeroLineColor: "#dde4eb"
          },
          barPercentage: 1
        }]
      },
    };
    
    return (
        <div>
        <div className="row page-title-header">
          <div className="col-12">
            <div className="page-header">
              <h1>Tableau de bord</h1>
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <Row>
              <div className="col-xl-6 col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title mb-0">Nombres d'équipes</h4>
                    </div>
                    <h3 className="font-weight-medium mb-4">{overview.members.toLocaleString()}</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title mb-0">Nombres de commandes</h4>
                    </div>
                    <h3 className="font-weight-medium">{overview.orders.toLocaleString()}</h3>
                  </div>
                </div>
              </div>
              <Col md={12} className="grid-margin">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h4 className="card-title mb-0">Évolution des bénéfices au cours de l'année <span style={{color: 'var(--primary-color)'}}>{selectedYear}</span></h4>
                      <div className="dropdown">
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                          {selectedYear}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Année</Dropdown.Header>
                            {years.map(year => 
                              <Dropdown.Item key={year} onClick={() => setSelectedYear(year)}>{year}</Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div>
                      <Bar data={marketingOverviewData} options={profitOverviewOptions} height={100} id="profitsOverview"/>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div> 
    ) ;
}
export default Dashboard;