import React from "react";
import { Modal } from "react-bootstrap";

const UnPaidModal = ({show, close, index, markAsPaid}) => {
    const handleUnPaid = () => {
        markAsPaid(1);
        close();
    }
    
    return (
        <Modal centered show={show} dialogClassName="asako__modal">
            <Modal.Header>
                <h4>
                    Marqué comme non-payé
                </h4>
                <i className="bi bi-x-lg" onClick={() => close()}></i>
            </Modal.Header>
            <Modal.Body>
                <p>Cette commande a déjà été marqué comme <span>payé</span>, voulez-vous vraiment la marqué comme <span>non-payé</span></p>
            </Modal.Body>
            <Modal.Footer>
                <button className="asako-btn asako-btn-danger" onClick={handleUnPaid}>
                    Marqué non-payé
                </button>
            </Modal.Footer>
        </Modal>
    );
}
export default UnPaidModal;