import React, { useState } from "react";
import DeleteTaskCardModal from "./DeleteTaskCardModal";
import { Modal } from "react-bootstrap";

const CardOptionModal = ({ card, cardIndex, close, setShowOption, removed }) => {

    const handleRemove = () => {
        removed(cardIndex);
    }

    const [showDeleteModal ,setShowDeleteModal] = useState(false);

    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
    
    return (
        <div className="option-modal card-option">
            <div className="header">
                <h6>Listes des actions</h6>
                <div className="close-icon">
                    <i className="bi bi-x" onClick={close}></i>
                </div>
            </div>
            <hr />
            <div className="column-option-container">
                <ul>
                    <li className="item" onClick={() => setShowOption('move-card')}>
                        Déplacer
                    </li>
                    <li 
                    className="item"
                    onClick={toggleDeleteModal}
                    >
                        Archiver
                    </li>
                    <Modal show={showDeleteModal} onHide={toggleDeleteModal} centered className="asako__modal">
                        <DeleteTaskCardModal card={card} remove={handleRemove} close={toggleDeleteModal}/>
                    </Modal>
                </ul>
            </div>
        </div>
    );
}

export default CardOptionModal;