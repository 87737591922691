import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import FormRequired from "./FormRequired";

const FormMultiSelect = ({
    placeholder, value, error, handleChange, classIcon, label, options, name, formClass, required
}) => {
    return (
        <>
        {!!name 
            ? 
            <Form.Group>
                <Form.Label>
                    <i className={classIcon}></i> <span>{label}</span> {required && <FormRequired/>}
                </Form.Label>
                <Select
                    isMulti
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    onChange={(selected) => handleChange(selected, name)}
                    className={`${!!error ? 'basic-multi-select form-has-error' : 'basic-multi-select'} ${formClass}`}
                    classNamePrefix="select"
                    isInvalid={!!error}
                />
                <div className={!!error ? 'form-error' : ''}>
                    {error}
                </div>
            </Form.Group>
            :
            <Form.Group>
                <Form.Label>
                    <i className={classIcon}></i> <span>{label}</span>{required && <FormRequired/>}
                </Form.Label>
                <Select
                    isMulti
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    onChange={handleChange}
                    className={!!error ? 'basic-multi-select form-has-error' : 'basic-multi-select'}
                    classNamePrefix="select"
                    isInvalid={!!error}
                />
                <div className={!!error ? 'form-error' : ''}>
                    {error}
                </div>
            </Form.Group>
        }
        </>
    );

}
export default FormMultiSelect;