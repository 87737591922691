import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from 'pages/admin/Dashboard.jsx';
import SupplierPage from '../pages/admin/SupplierPage';
import SupplierDetailPage from '../pages/admin/SupplierDetailPage';
import AddSupplierPage from '../pages/admin/AddSupplierPage';
import MembersListPage from 'pages/teams/MembersListPage';
import ProjectLayout from 'layouts/ProjectLayout';
import CashMovement from 'pages/projects/cashing/CashMovementPage';
import CashMovementParameterPage from 'pages/projects/cashing/CashMovementParameterPage';
import TaskPage from 'pages/projects/teams/TaskPage.jsx';
import { TaskProvider } from 'contexts/TaskContext';
import ProjectPage from 'pages/admin/ProjectPage';
import OrderPage from 'pages/projects/clients/OrderPage';
import NewOrderPage from 'pages/projects/clients/NewOrderPage';
import OrderDetailPage from 'pages/projects/clients/OrderDetailPage';

const ProjectRoute = () => {
    return (
        <ProjectLayout>
            <Switch>
                <Route exact path="/projects/choose" component={ ProjectPage } />
                <Route exact path="/projects/:projectId/suppliers" component={ SupplierPage } />
                <Route exact path="/projects/:projectId/suppliers/:id" component={ SupplierDetailPage } />
                <Route exact path="/projects/:projectId/add-suppliers" component={ AddSupplierPage } />
                <Route exact path="/projects/:projectId/dashboard" component={ Dashboard } />
                <Route exact path="/projects/:projectId/teams/members" component={ MembersListPage } />
                <Route exact path="/projects/:projectId/cash-movements/flows" component={ CashMovement } />
                <Route exact path="/projects/:projectId/cash-movements/parameters" component={ CashMovementParameterPage } />
                <Route exact path="/projects/:projectId/orders" component={ OrderPage } />
                <Route exact path="/projects/:projectId/new-order" component={ NewOrderPage } />
                <Route exact path="/projects/:projectId/orders/:orderId" component={ OrderDetailPage } />
                <TaskProvider>
                    <Route exact path="/projects/:projectId/teams/tasks" component={ TaskPage } />
                </TaskProvider>
            </Switch>
        </ProjectLayout>
    );
}

export default ProjectRoute;